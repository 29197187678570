
import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Link } from 'react-router-dom';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";
import Parser, { domToReact } from 'html-react-parser';

import Meta from '../../common/Meta';
import './Services.css';

const Services = (props)=>{ 

  const [data,set_data]          = useState('') 
  const [metaData,set_metaData]  = useState('')
  const [services,set_services]  = useState([])  


  useEffect( ()=>{	
    fetchData()  
    fetchServices()    
  },[])	

  const fetchData = async ()=>{ 
      let q = query(collection(db, "pages"));	        
          q = query(q, where("seo_url", "==",  "services" )); 
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map( (doc) => {				
				return { 
					...doc.data(), 
					id: doc.id,             
				}
			})
      let docRow = result[0]
      set_data(docRow)
      set_metaData({
        meta_title			  : docRow.meta_title,
        meta_description	: docRow.meta_description,
        meta_keywords		  : docRow.meta_keyword,
        seo_url		        : docRow.seo_url,
      })
  }

  const fetchServices = async ()=>{ 
    let q = query(collection(db, "services"));	
        q = query(q, where("status", "==", 1 ));     
        q = query(q, orderBy('sort_order','asc'));	
    const querySnapshot = await getDocs(q);	
    let result = querySnapshot.docs.map((doc) => ({ 
        ...doc.data(), 
        id: doc.id,             
    })); 
    set_services(result)
}


  const parseWithLinks = (text)=>{
    const options = {     
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href) {
          return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
        }
      }
    };        
    return Parser(text, options);
  }


  return (  
    <>  
    <Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

    <main id="main">
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Services</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li>Services</li>
          </ol>
        </div>
      </div>
    </section>
    <section className="inner-page services">
          <div className='container col-12' style={{textAlign:"center"}}>

          { data ? parseWithLinks(""+data.content+"") :  ''  }

          <div className="row equal-height" style={{textAlign:"left",paddingTop:"30px"}}>

          { services ?
            services.map((item,i)=>{
              return (
                <div key={i} className="col-md-6 col-12 servv"> 
                    <Link to={`../${item.seo_url}`}> 
                    <div className="card content">
                    <div className="row g-0 content">
                    <div className="col-lg-4 col-md-12 col-12" style={{background:"#eee"}}>
                    <div className="my-auto p-3">
                    <img src={item.image} className="img-fluid" alt="" />
                    </div>                      
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                    <div className="card-body p-3">
                    <h5 className="card-title">{item.title}</h5>
                    <div className="card-text">{parseWithLinks(item.short_description)}</div>
                    <p className="card-text d-flex justify-content-start" style={{color:'#106eea',fontSize:'14px'}}>...Read More</p>
                    </div>
                    </div>
                    </div>
                    </div>
                    </Link>      
                </div>        
              )
            })
            :
            ''
          }

          </div>    
      </div>
    </section>
    </main>
    
    
    </>
  );
 
}
export default Services;

