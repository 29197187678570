import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Parser, { domToReact } from 'html-react-parser';
import { db } from "../../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

const Faq = (props)=>{  
  
  const [data,set_data]  = useState([]) 

  useEffect( ()=>{	
    fetchData()   
  },[])	

  const parseWithLinks = (text)=>{
    const options = {     
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href) {
          return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
        }
      }
    };        
    return Parser(text, options);
  }

  const fetchData = async ()=>{ 
    let q = query(collection(db, "faq"));	        
        q = query(q, where("status", "==",  1 )); 
        q = query(q, orderBy('sort_order','asc'));	
    const querySnapshot = await getDocs(q);	
    let result = querySnapshot.docs.map((doc) => ({ 
        ...doc.data(), 
        id: doc.id,             
    })); 
    set_data(result)
  }

  if(!data){
    return '';
  }

  var Lcount = 0
  var Rcount = 0

  return (  
    <>  
    <div className="faq_area section_padding_130" id="faq">
        <div className="container">            
            <div className="section-title mb-5">
              <h3>Answers to Commonly Asked Questions <br />Your <span>Queries</span>, Our <span>Solutions</span>.</h3>              
            </div>
            <div className="row justify-content-center">  
                
                  <div className="col-lg-6 col-md-12 col-12">
                  <Accordion  className="accordion faq-accordian">
                  {data.map( (item,i)=>{
                      Lcount++
                      if(Lcount<=5){
                        return(
                          <Accordion.Item eventKey={i} key={i}>
                            <Accordion.Header>{item.question}</Accordion.Header>
                            <Accordion.Body>{parseWithLinks(item.answer)}</Accordion.Body>
                          </Accordion.Item>
                        )
                      }                     
  
                  })}
                  </Accordion>           
                  </div>

                  <div className="col-lg-6 col-md-12 col-12">
                  <Accordion  className="accordion faq-accordian">
                  {data.map( (item,i)=>{
                      Rcount++
                      if(Rcount>5){
                        return(
                          <Accordion.Item eventKey={i} key={i}>
                            <Accordion.Header>{item.question}</Accordion.Header>
                            <Accordion.Body>{parseWithLinks(item.answer)}</Accordion.Body>
                          </Accordion.Item>
                        )
                      }                     
  
                  })}
                  </Accordion>           
                  </div>
                            
            </div>
        </div>
    </div>
    </>
  )
}
export default Faq 