
import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Link } from 'react-router-dom';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";
import Parser, { domToReact } from 'html-react-parser';

import Meta from '../../common/Meta';

const Privacy_policy = (props)=>{ 

  const [data,set_data]          = useState('') 
  const [metaData,set_metaData]  = useState('') 

  useEffect( ()=>{	
    fetchData()   
  },[])	

  const fetchData = async ()=>{ 
      let q = query(collection(db, "pages"));	        
          q = query(q, where("seo_url", "==",  "privacy-policy" )); 
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map( (doc) => {				
				return { 
					...doc.data(), 
					id: doc.id,             
				}
			})
      let docRow = result[0]
      set_data(docRow)
      set_metaData({
        meta_title			  : docRow.meta_title,
        meta_description	: docRow.meta_description,
        meta_keywords		  : docRow.meta_keyword,
        seo_url		        : docRow.seo_url,
      })
  }

  const parseWithLinks = (text)=>{
    const options = {     
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href) {
          return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
        }
      }
    };        
    return Parser(text, options);
  }


  return (  
    <>  
    <Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

    <main id="main">
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Privacy policy</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li>Privacy policy</li>
          </ol>
        </div>
      </div>
    </section>
    {/* <section className="inner-page">
      <div className="container content-space-3 content-space-lg-4">
      <div className="card-body">
  <div className="mb-7">
    <h4>Introduction</h4>

    <p>Thanks for using our products and services ("Services"). The Services are provided by Pixeel Ltd. ("Space"),
      located at 153 Williamson Plaza, Maggieberg, MT 09514, England, United Kingdom.</p>

    <p>By using our Services, you are agreeing to these terms. Please read them carefully.</p>

    <p>Our Services are very diverse, so sometimes additional terms or product requirements (including age requirements)
      may apply. Additional terms will be available with the relevant Services, and those additional terms become part
      of your agreement with us if you use those Services.</p>
  </div>

  <div className="mb-7">
    <h4>Using our services</h4>

    <p>You must follow any policies made available to you within the Services.</p>

    <p>Don't misuse our Services. For example, don't interfere with our Services or try to access them using a method
      other than the interface and the instructions that we provide. You may use our Services only as permitted by law,
      including applicable export and re-export control laws and regulations. We may suspend or stop providing our
      Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct.
    </p>

    <p>Using our Services does not give you ownership of any intellectual property rights in our Services or the content
      you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise
      permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don't
      remove, obscure, or alter any legal notices displayed in or along with our Services.</p>
  </div>

  <div className="mb-7">
    <h4>Privacy and copyright protection</h4>

    <p>Space's privacy policies explain how we treat your personal data and protect your privacy when you use our
      Services. By using our Services, you agree that Space can use such data in accordance with our privacy policies.
    </p>

    <p>We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to
      the process set out in the U.S. Digital Millennium Copyright Act.</p>

    <p>We provide information to help copyright holders manage their intellectual property online. If you think somebody
      is violating your copyrights and want to notify us, you can find information about submitting notices and Space's
      policy about responding to notices in our Help Center.</p>
  </div>

  <div className="mb-7">
    <h4>Your content in our services</h4>

    <p>Some of our Services allow you to upload, submit, store, send or receive content. You retain ownership of any
      intellectual property rights that you hold in that content. In short, what belongs to you stays yours.</p>

    <p>When you upload, submit, store, send or receive content to or through our Services, you give Space (and those we
      work with) a worldwide license to use, host, store, reproduce, modify, create derivative works (such as those
      resulting from translations, adaptations or other changes we make so that your content works better with our
      Services), communicate, publish, publicly perform, publicly display and distribute such content. The rights you
      grant in this license are for the limited purpose of operating, promoting, and improving our Services, and to
      develop new ones. This license continues even if you stop using our Services (for example, for a business listing
      you have added to Space Maps). Some Services may offer you ways to access and remove content that has been
      provided to that Service. Also, in some of our Services, there are terms or settings that narrow the scope of our
      use of the content submitted in those Services. Make sure you have the necessary rights to grant us this license
      for any content that you submit to our Services.</p>
  </div>
</div>
      </div>
    </section> */}
    { data ? parseWithLinks(""+data.content+"") :  <section className="inner-page"><div className="container"></div></section>  } 
    </main>
    
    </>
  );
 
}
export default Privacy_policy;

