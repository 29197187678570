import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';
import Parser, { domToReact } from 'html-react-parser';

import { db } from "../../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

import './Featured_services.css';
const Featured_services = (props)=>{   

  const [data,set_data]  = useState([]) 

  useEffect( ()=>{	
    fetchData()   
	},[])	

  const parseWithLinks = (text)=>{
    const options = {
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href) {
          return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
        }
      }
    };        
    return Parser(text, options);
  }

  const fetchData = async ()=>{ 
      let q = query(collection(db, "services"));	
          q = query(q, where("status", "==", 1 ));     
          q = query(q, orderBy('sort_order','asc'));	
      const querySnapshot = await getDocs(q);	
      let result = querySnapshot.docs.map((doc) => ({ 
          ...doc.data(), 
          id: doc.id,             
      })); 
      set_data(result)
  }

  return (  
    <>  
    <section id="featured-services" className="featured-services">

      <div className="container">
      <div className="row equal-height">

      {data.map((item,i)=>{
            let bgcolor = '#dee2e6'
            if(i % 2 == 0) {
              bgcolor = '#dedede'
            }

              return (
                <div key={i} className="col-lg-3 col-md-6 col-12">
                <div className="our-team-main content" style={{background:bgcolor,color:"#ccc"}}>                 
                  <Link to={item.seo_url}>
                  <div className="team-back" >                  
                    <div className="icon-box pb-2">              
                    <img src={item.image} style={{width:"64px"}} className="img-fluid" alt="" />                   
                    </div>
                    <h2 style={{color:"#000"}}>{item.title}</h2>    
                    <div style={{color:"#333"}}>{parseWithLinks(item.short_description)}</div>                       
                  </div>
                  </Link>             
                </div>
              </div>
              )
        })
      }

      </div>
    </div>
    </section>
    </>
  )
}
export default Featured_services