
import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Link } from 'react-router-dom';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

import Parser, { domToReact } from 'html-react-parser';

import { useSelector, useDispatch } from 'react-redux'
import { fetchSetting } from '../../../redux/slice/setting'

import Meta from '../../common/Meta';


const Thank_you = (props)=>{ 

  const dispatch = useDispatch()
	const settingState = useSelector( (state)=> state.setting )  
  const setting      = (settingState.data) ? JSON.parse(settingState.data) : null;


  const [metaData,set_metaData]  = useState({}) 
  const [data,set_data]          = useState('') 

  useEffect( ()=>{	
    fetchData()  
    dispatch(fetchSetting())	 
  },[])	

  const fetchData = async ()=>{ 
      let q = query(collection(db, "pages"));	        
          q = query(q, where("seo_url", "==",  "thank-you" )); 
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map( (doc) => {				
				return { 
					...doc.data(), 
					id: doc.id,             
				}
			})
      let docRow = result[0]
      set_data(docRow)
      set_metaData({
        meta_title			  : docRow.meta_title,
        meta_description	: docRow.meta_description,
        meta_keywords		  : docRow.meta_keyword,
        seo_url		        : docRow.seo_url,
      })
  }

  const parseWithLinks = (text)=>{
    const options = {     
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href) {
          return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
        }
      }
    };        
    return Parser(text, options);
  }

  return (  
    <>  
    <Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

    <main id="main">
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Thank You</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/contact-us">Contact Us</Link></li>
            <li>Thank You</li>
          </ol>
        </div>
      </div>
    </section>
    <section id="contact" className="contact">
      <div className="container"> 
        <div className="row">   
          <div className="col-lg-12">
          { data ? parseWithLinks(""+data.content+"") :  ''  }
          </div>
        </div>
      </div>
    </section>
    </main>
    </>
  );
 
}
export default Thank_you;

