import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, Navigate, useParams } from 'react-router-dom'; 
import Parser, { domToReact } from 'html-react-parser';
import Carousel from 'react-bootstrap/Carousel';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

import Meta from '../../common/Meta';
import './Work_details.css';

const Work_details = (props)=>{ 

  const params = useParams();	
  const id     = (params.id) ? params.id : ''

  const [data,set_data]  = useState() 
  const [metaData,set_metaData]  = useState({}) 

  useEffect( ()=>{	
    fetchData(id)   
  },[id])	

  const fetchData = async (id)=>{ 
    var obj = []

    const promise_1 = new Promise( async (resolve,reject) =>{
      const docRef = doc(db, "portfolio", id);
      const docSnap = await getDoc(docRef);      
      let row = { 
          ...docSnap.data(), 
					id:id,             
			}
			resolve(row)
		})

    const promise_2 = new Promise( async (resolve,reject) =>{
			let q = query(collection(db, "technologies"), orderBy('name','asc'));	
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map((doc) => ({ 
				...doc.data(), 
				id: doc.id,             
			})); 
			resolve(result)
		})

    const promise_3 = new Promise( async (resolve,reject) =>{
			let q = query(collection(db, "services"), orderBy('title','asc'));	
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map((doc) => ({ 
				...doc.data(), 
				id: doc.id,             
			})); 
			resolve(result)
		})

		const promise_4 = new Promise( async (resolve,reject) =>{
			let q = query(collection(db, "industries"), orderBy('name','asc'));	
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map((doc) => ({ 
				...doc.data(), 
				id: doc.id,             
			})); 
			resolve(result)
		})

    Promise.all([promise_1, promise_2, promise_3, promise_4]).then( ([portfolio, technologies, services, industries])=>{			

      let technologiesStr   = portfolio.technologies
      let technologiesArr   = technologiesStr.split(',')

      let technologies_data = technologiesArr.map( (tech,i)=>{
        return technologies.find( a => a.id === tech ).name
      })
      
      portfolio['technologies_data'] = technologies_data
      portfolio['service_data']      = services.find( a => a.id === portfolio.service_id )
			portfolio['idustry_data']      = industries.find( a => a.id === portfolio.industry_id )
			
			set_data(portfolio)	
      set_metaData({
        meta_title			  : portfolio.name,
        meta_description	: portfolio.short_description,
        meta_keywords		  : '',
        seo_url		        : 'work-details/' + portfolio.id,
      })	

		})	    
   
  }

  if(!data){
    return ''
  }

  
  return (  
    <> 
    <Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>	
    </Helmet>				
    </HelmetProvider>

    <main id="main">

    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{data.name}</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/our-works">Our works</Link></li>
            <li>Work details</li>            
          </ol>
        </div>
      </div>
    </section>

    <section id="portfolio-details" className="portfolio-details">
      <div className="container">
        <div className="row gy-4 equal-height">

          <div className="col-lg-7">
          <Carousel 
          variant="dark" 
          interval={8000}
          controls={false}
          slide={false}
          >
            <Carousel.Item>
            <div className="row content">
                <div className="col-md-12">
                <img src={data.image} className="img-fluid" alt="" />
                </div>
            </div>
            </Carousel.Item>            
            
          </Carousel>
          </div>

          <div className="col-lg-5">
          <div className="portfolio-info content pt-0">          
              <h4>Work details</h4>
              <hr />
              <ul>
              { data.service_data ? <li><strong>Service</strong>: {data.service_data.title}</li> : '' }
              { data.idustry_data ? <li><strong>Industries</strong>: {data.idustry_data.name}</li> : '' }
              { data.client_name ? <li><strong>Client</strong>: {data.client_name}</li> : '' }
              { data.location ? <li><strong>Location</strong>: {data.location}</li> : '' }
              { data.project_date ? <li><strong>Project date</strong>: {data.project_date}</li> : '' }
              { data.project_url ? <li><strong>Project URL</strong>: <a href={data.project_url} target="_blank" rel="nofollow">{data.project_url}</a></li> : '' }
              </ul>  
              <h5>Technology Used</h5>
              <ul className="tech-list">
                {data.technologies_data && data.technologies_data.map( (item,i)=>{
                  return(
                    <li key={i}>{item}</li>
                  )
                })}
              </ul> 
              <hr /> 
              {data.short_description && Parser(''+data.short_description+'')}  
          </div>
          </div>

          <div className="col-lg-12">
            <div className="portfolio-description">
            {data.long_description && Parser(''+data.long_description+'')}
            </div>
          </div>

        </div>
      </div>
    </section>
    </main>
    
    
    </>
  );
 
}
export default Work_details;

