
import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Link } from 'react-router-dom';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";
import Parser, { domToReact } from 'html-react-parser';

import './Graphic_design.css';
import Meta from '../../common/Meta';
import Contact_form from '../contact/Contact_form';

const Graphic_design = (props)=>{ 

    const [data,set_data]          = useState('') 
    const [metaData,set_metaData]  = useState({}) 

    useEffect( ()=>{	
      fetchData()   
    },[])	
  
    const fetchData = async ()=>{ 
        const docRef = doc(db, "services", "YWKg9N7CUzujGRwLDBMV");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let docRow = { 
                ...docSnap.data(), 
                id: docSnap.id,             
            }
            set_data(docRow)
            set_metaData({
                meta_title		    : docRow.meta_title,
                meta_description	: docRow.meta_description,
                meta_keywords		: docRow.meta_keyword,
                seo_url		        : docRow.seo_url,
            })
        }
    }

    const parseWithLinks = (text)=>{
        const options = {     
          replace: ({ name, attribs, children }) => {
            if (name === 'a' && attribs.href) {
              return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
            }
          }
        };        
        return Parser(text, options);
    }

  return (  
    <>  
    <Meta metaData={metaData} /> 
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

    <main id="main">
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{ (data) ? data.title : '' }</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li>{ (data) ? data.title : '' }</li>            
          </ol>
        </div>
      </div>
    </section>

    <section className="grphd01" style={{backgroundImage:'url(assets/img/services/graphic-design/bg-row-circle-pattern.png)'}}>
      <div className="container">
      <div className="row">
          <div className="col-12 pull-right wow fadeIn no-padding">
              <div className="col-12 sm-text-center xs-no-padding-lr last-paragraph-no-margin margin-40px-bottom sm-margin-5px-bottom px0 ">
                                   
                  <div className="row">
                      <div className="col-lg-7 col-md-12 col-12">                        
                        {/*
                        <div>
                          <div className="heading-main">
                            <p className="title">Ajtechnoweb </p>
                            <h2 className="sec1_heading">Best Web Designing Company in Kolkata</h2>
                          </div> 
                          <p className="width-100 sm-width-100 about-text wow fadeInUp  text-justify">
                              Ajtechnoweb has climbed its way to the very top and secured itself as the best web design
                              company in Kolkata since its establishment in 2013. Right from then our goal has been to craft
                              high-quality websites for our clients, and be by their side at every
                              step of the process.
                          </p>
                          <p className="width-100 sm-width-100 about-text wow fadeInUp  text-justify">
                              In the last ten years, the vibrant team at Ajtechnoweb has built a rock-solid reputation for
                              our company in the industry. We are the best web designers in Kolkata, that create ingenious
                              user-centric websites for our clients. The innovative brand solutions
                              that we design for our clients draw customers to their websites. The customer-oriented approach
                              that we deem supremely important, helps us to model websites in a manner that encourages user
                              engagement.
                          </p>
                          <p className="width-100 sm-width-100 about-text wow fadeInUp  text-justify">
                              Whether you are an entrepreneur setting up a new business, a veteran company looking to revamp
                              your business, or any other type of enterprise, we’ve got your back. Irrespective of the
                              juncture your company is at, we will conceptualize, craft designs,
                              and assist you to make the most suitable website for your business. Our knack is to give rise to
                              websites that harmoniously combine your precise business needs, target audience, market, and the
                              like.
                          </p>
                        </div>
                       */}
                       { data && data.section[0] &&                           
                            <>
                            {parseWithLinks(''+data.section[0].section+'')}
                            </>                                                    
                        }
                      </div>
                      <div className="col-lg-5 col-md-12 col-12">
                          <div className="formbg contact">
                              <div style={{padding:"15px 0px 5px 30px"}}>
                              <h3 className="form-text">Ready to <br />Get Started?</h3>
                              <div className="sep_line"></div>
                              </div>
                              <Contact_form />
                          </div>
                      </div>
                  </div>
                  {/*
                  <p className="width-100 sm-width-100 about-text wow fadeInUp  text-justify cls-bghf">
                      Being an established company, we understand the ins and outs of the issues a user faces while exploring
                      a website. Our designers create websites that ensure an enjoyable user experience, be it in visuality or
                      in functionality. We
                      smoothen and eliminate any bumps on the road, a user might encounter. Dynamic illustrations, bold fonts,
                      grid design, full-page header, playful cursors, etc, are a few of the visual web design trends our
                      professionals implement
                      to make the experience more aesthetically appealing.
                  </p>
                  */}
                  { data && data.section[1] &&                           
                    <>
                    {parseWithLinks(''+data.section[1].section+'')}
                    </>                                                                        
                  }
              </div>
          </div>
      </div>
      </div>
    </section>    
    
    {/*
    <section className="grphd02">
        <div className="container">
            <div className="row justify-content-center mt-20">
                <div className="col-md-12">
                    <h2 className="secline text-center">A Web Design Company in Kolkata for Responsive Design
                        Solutions</h2>
                    <div className="row mrgg">
                        <div className="col-md-6">
                            <p className="about-text text-justify">Ajtechnoweb is a web designing company that
                                renders remarkable web design services which are both innovative and cost-effective. Quality
                                services are offered to all our clients, from Small &amp; Medium businesses to various
                                Corporate
                                Enterprises we provide custom-tailored responsive web solutions that benefit the respective
                                companies.</p>
                        </div>
                        <div className="col-md-6">
                            <p className="about-text text-justify ch-bord">Our doors are always open, seeking new
                                stimulating projects and harnessing the power of the internet to expand our clients’
                                business. Our team of professionals skillfully delivers proficient websites that are a true
                                testament to a flawless user experience. Our expertise in the field of web design and web
                                development is unmatched. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>    

    <section className="grphd03">
        <div className="container">
            <p className="text-center text-white">We are dedicated to providing a spectrum of web solutions that cater to
                internet marketing and branding demands. In website design solutions, we pride ourselves in being a website
                design company ahead of
                its time as we exclusively desin <u>SEO</u> friendly and flexible
                websites. Which would be of service to improve user experience and also reach the top ranks on search
                engines. Definitely a double-win situation!
            </p>
            <div className="explore-btn margin-40px-top">
            <Link to="/contact-us" className="btn btn-large  btn-deep-white">Connect Us</Link>
            </div>
        </div>
    </section>    

    <section className="grphd04"> 
        <div className="container">
            <div className="dream-dots justify-content-center">            
            </div>
            <div className="row">
                <h2 className="text-center">The Hallmarks of a Good Website Design</h2>  
                <p>&nbsp;</p>                          
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div className="inner-box">
                        <div className="inner-content">
                            <div className="icon-box">
                            <img src="/assets/img/services/graphic-design/responsive-website-design.png" alt="" className="img-responsive" />    
                            </div>
                            <h3>A Responsive Website Design</h3>
                            <div className="text">Responsive web design (RWD) is a dynamic approach in web designing that transforms the appearance of the website, simply based on the device being used and its screen size. Be it a mobile phone, a tablet, or a PC, no matter
                                what device, our websites accommodate all of them.</div>                            
                        </div>                        
                        <div className="overlay-box"></div>
                    </div>
                </div>
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div className="inner-box">
                        <div className="inner-content">
                            <div className="icon-box">
                            <img src="/assets/img/services/graphic-design/sophisticated-graphic-design.png" alt="" className="img-responsive" />    
                            </div>
                            <h3>Designs of Sophistication</h3>
                            <div className="text">Elegance and uniqueness, there is truly none other like this! All our websites are custom-made and tailored to the specifications of the business. Our designs are leagues apart in comparison to other web layouts.</div>
                            </div>                        
                        <div className="overlay-box"></div>
                    </div>
                </div>
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div className="inner-box">
                        <div className="inner-content">
                            <div className="icon-box">
                            <img src="/assets/img/services/graphic-design/illustrious-photographic-designs.png" alt="" className="img-responsive" />    
                            </div>
                            <h3>Illustrious Graphic Designs</h3>
                            <div className="text">The remarkable graphics we design grabs the customer’s attention instantly. The infographics we make deliver content at a quick glance. The designs give life to the content, which makes it almost pop out of the screen. Thereby
                                enriching the user experience. </div>
                        </div>                        
                        <div className="overlay-box"></div>
                    </div>
                </div>
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div className="inner-box">
                        <div className="inner-content">
                            <div className="icon-box">
                            <img src="/assets/img/services/graphic-design/amp-optimization.png" alt="" className="img-responsive" />    
                            </div>
                            <h3>AMP Optimization</h3>
                            <div className="text">Attain more traffic on your webpage through the optimization of mobile web browsing. AMP Pages uses only a tenth of the data of regular pages and thereby loads in the blink of an eye. AMP drastically improves mobile web browsing
                                by miles. </div>
                                </div>                        
                        <div className="overlay-box"></div>
                    </div>
                </div>
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div className="inner-box">
                        <div className="inner-content">
                            <div className="icon-box">
                            <img src="/assets/img/services/graphic-design/ssl-certificate.png" alt="" className="img-responsive" />    
                            </div>
                            <h3>SSL Certification</h3>
                            <div className="text">Safety first is a principle that cannot be abandoned. SSL is a security protocol that encrypts information so that only the intended recipient can gain access to it. SSL is a must-have for all e-commerce companies to build
                                trustworthy relationships with their customers.</div>
                                </div>                        
                        <div className="overlay-box"></div>
                    </div>
                </div>
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div className="inner-box">
                        <div className="inner-content">
                            <div className="icon-box">
                            <img src="/assets/img/services/graphic-design/payment-gateway.png" alt="" className="img-responsive" />    
                            </div>
                            <h3>Simplified through Payment Gateway</h3>
                            <div className="text">Provide a seamless user experience, collect payments online to maintain an adaptable relationship with your clients. Payment gateways are perhaps the quickest and easiest method to pass transaction information from a payment
                                portal to the receiving bank.</div>
                                </div>                        
                        <div className="overlay-box"></div>
                    </div>
                </div>
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div className="inner-box">
                        <div className="inner-content">
                            <div className="icon-box">
                            <img src="/assets/img/services/graphic-design/ui.png" alt="" className="img-responsive" />    
                            </div>
                            <h3>Great User Experience (UX)</h3>
                            <div className="text">It takes no more than 0.9 seconds for users to form a complete opinion. That is why it is mandatory to create a wonderful user experience (UX) for your website visitors. </div>
                            </div>                        
                        <div className="overlay-box"></div>
                    </div>
                </div>
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div className="inner-box">
                        <div className="inner-content">
                            <div className="icon-box">
                            <img src="/assets/img/services/graphic-design/blog.png" alt="" className="img-responsive" />    
                            </div>
                            <h3>Captivating Blogs</h3>
                            <div className="text">People are driven towards content that resonates with them. Increase website traffic by creating blogs that are engaging, thereby encouraging customers to stay on your website for longer and further browse your website.
                            </div>
                            </div>                        
                        <div className="overlay-box"></div>
                    </div>
                </div>
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div className="inner-box">
                        <div className="inner-content">
                            <div className="icon-box">
                            <img src="/assets/img/services/graphic-design/chat.png" alt="" className="img-responsive" />    
                            </div>
                            <h3>Chat through LiveChat Integration</h3>
                            <div className="text">Converse with your customers anytime through implementing LiveChat Integration. Never miss out on potential clients anymore. A hassle-free engagement for your customers by providing an end-to-end conversation on your website.</div>
                            </div>                        
                        <div className="overlay-box"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>    

    <section className="grphd05">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                      <div className="ct-heading h-align-  sub- ct-heading-left item-st-line-left1">
                          <h2 className="item--title st-line-left1">
                                <span>
                                <i></i>
                                Web Design Services We Offer</span>
                          </h2>
                      </div>
                  </div>            
              </div>
              <hr />
              <div className="row text-center labl-serv">
                  <div className="col-md-4">
                      <div className="card">
                          <div className="choose-image-box">
                              <img src="assets/img/services/graphic-design/custom website design-04.svg" />
                          </div>
                          <div className="card-body">
                              <h4 className="card-title">
                                  Custom Web Design
                              </h4>
                              <p className="card-text">In this fast-moving global market, Take your online business to the
                                  succeeding level with our custom web design services. At Ajtechnoweb, you'll get a visually
                                  striking website that is easy to navigate and user-friendly. Our Innovative Web Design team
                                  collaborates with the clients and transforms every simple idea into a business vision with
                                  goals, resulting in the
                                  creation of a well-optimized custom websites with the best user experience tailored to
                                  meet the branding needs of clients.
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4">
                      <div className="card">
                          <div className="choose-image-box">
                              <img src="assets/img/services/graphic-design/wordpress design-05.svg" />
                          </div>
                          <div className="card-body">
                              <h4 className="card-title"> WordPress Design</h4>
                              <p className="card-text">
                              Transform your online presence with our expert WordPress design services.
                                  Our expert design team creates custom Responsive WordPress Websites which are SEO Friendly
                                  with aesthetic look with Flexible and adaptable plugins, especially with WordPress themes
                                  with Multiple Options help to create unique stylish, and professional look websites. Elevate
                                  your brand's digital experience with us.
                                  </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4">
                      <div className="card">
                          <div className="choose-image-box">
                              <img src="assets/img/services/graphic-design/ui ux-02.svg" />
                          </div>
                          <div className="card-body">
                              <h4 className="card-title">Web UI/UX</h4>
                              <p className="card-text">We craft intuitive and engaging UI/UX designs that ensure your website
                                  visitors stay hooked. Our experienced designers understand your audience's needs and create
                                  seamless interfaces that enhance user experience. We integrate effective calls-to-action for
                                  better conversions.
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row text-center mt-20 labl-serv">
                  <div className="col-md-4">
                      <div className="card">
                          <div className="choose-image-box">
                              <img src="assets/img/services/graphic-design/cms website design-03.svg" />
                          </div>
                          <div className="card-body">
                              <h4 className="card-title">CMS Web Design</h4>
                              <p className="card-text">Revamp your website with our CMS web design services that provide you with
                                  a user-friendly interface, giving you complete control over your content. Let us help you
                                  create a stunning online presence that leaves a lasting impression.
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4">
                      <div className="card">
                          <div className="choose-image-box">
                              <img src="assets/img/services/graphic-design/landing page design-06.svg" />
                          </div>
                          <div className="card-body">
                              <h4 className="card-title">
                                  Landing Page Design
                              </h4>
                              <p className="card-text">Increase your website conversions with our landing page design services.
                                  Our team creates high-quality mobile-friendly landing pages with user-friendly interfaces.
                                  We help businesses achieve their marketing goals through our effective and professional
                                  landing page design services.
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4">
                      <div className="card">
                          <div className="choose-image-box">
                              <img src="assets/img/services/graphic-design/redesign website-01.svg" />
                          </div>
                          <div className="card-body">
                              <h4 className="card-title">
                                  Redesign Website
                              </h4>
                              <p className="card-text">Refresh your online presence with our expert website redesign services. Our
                                  web design company offers website redesign that is revamping the site which can be updating
                                  content, improving site performance and navigation for better conversions, or refreshing
                                  layouts with modern and responsive designs.Upgrade your website today and stand out in the
                                  digital world.
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row text-center mt-20 labl-serv">
                  <div className="col-md-4">
                      <div className="card">
                          <div className="choose-image-box">
                              <img src="assets/img/services/graphic-design/static website design-08.svg" />
                          </div>
                          <div className="card-body">
                              <h4 className="card-title">
                                  Static Website Design
                              </h4>
                              <p className="card-text">Get user-friendly and visually appealing static websites through our static
                                  website design services. We provide cost-effective solutions that are optimized for search
                                  engines and deliver an effective online presence for businesses.
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4">
                      <div className="card">
                          <div className="choose-image-box">
                              <img src="assets/img/services/graphic-design/Dynamic web design-07.svg" />
                          </div>
                          <div className="card-body">
                              <h4 className="card-title">
                                  Dynamic Web Design
                              </h4>
                              <p className="card-text">Bring your website to life with our dynamic web design services. Our expert
                                  team creates captivating and functional websites that adapt to your business needs. Stand
                                  out online and impress your customers with our innovative designs.
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4">
                      <div className="card">
                          <div className="choose-image-box">
                              <img src="assets/img/services/graphic-design/Ecommerce website design-09.svg" />
                          </div>
                          <div className="card-body">
                              <h4 className="card-title">
                                  E-commerce Website Design
                              </h4>
                              <p className="card-text">Maximize your online sales with our e-commerce
                                      website design services. Our team crafts visually stunning and user-friendly
                                  websites that boost conversions and enhance customer experience. Take your business to the
                                  next level with our customized e-commerce solutions.
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    </section>    

    <section className="grphd06">
        <div className="container">
            <div className="row">
                <div className="col-md-5">
                    <div className="dream-dots">                     
                    </div>
                    <h3>Technologies in Website Design</h3>
                </div>
                <div className="col-md-7">
                    <p className="text-justify">Our adroit website designers in Kolkata possess proficiency in leveraging cutting-edge technologies to deliver world-className solutions to clients. We are committed to using the latest version of technologies to help your website gain a competitive
                        advantage. Some of the technologies that we work with;</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5 map" style={{backgroundImage:'url(assets/img/services/graphic-design/h6-map.png)'}}>
                    <h3 className="count">75<span className="pl"><sup>+</sup></span></h3>
                    <p className="cu">Technologies we used</p>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-4 col-xs-6">
                        <div className="client-image">
                            <picture>                                
                            <img src="assets/img/services/graphic-design/html.webp" alt="html" className="img-responsive m-auto" width="133" height="44" />
                            </picture>
                        </div>
                    </div>
                    <div className="col-md-4  col-xs-6">
                        <div className="client-image">
                            <picture>
                            <img src="assets/img/services/graphic-design/reactjs.webp" alt="js" className="img-responsive m-auto" width="133" height="44" />
                            </picture>
                        </div>
                    </div>
                    <div className="col-md-4  col-xs-6">
                        <div className="client-image">
                            <picture>
                            <img src="assets/img/services/graphic-design/css.webp" alt="css" className="img-responsive m-auto" width="133" height="44" />
                            </picture>
                        </div>
                    </div>
                    <div className="col-md-4  col-xs-6">
                        <div className="client-image">
                            <picture>
                            <img src="assets/img/services/graphic-design/jquery.webp" alt="jquery" className="img-responsive m-auto" width="133" height="44" />
                            </picture>
                        </div>
                    </div>
                    <div className="col-md-4  col-xs-6">
                        <div className="client-image">
                            <picture>
                            <img src="assets/img/services/graphic-design/angular.webp" alt="angular" className="img-responsive m-auto" width="133" height="44" />
                            </picture>
                        </div>
                    </div>
                    <div className="col-md-4  col-xs-6">
                        <div className="client-image">
                            <picture>
                            <img src="assets/img/services/graphic-design/bootstrap.webp" alt="bootstrap" className="img-responsive m-auto" width="133" height="44" />
                            </picture>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    */}

    {data && data.section &&
        data.section.map( (item,i)=>{
            if( i>=2 ){
                return(
                    <div key={i}>
                    {parseWithLinks(''+item.section+'')}
                    </div>
                )
            }            
        })
    }
    
    </main>  
    </>
  );
 
}
export default Graphic_design;

