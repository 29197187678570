import React, {useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useSelector, useDispatch } from 'react-redux'
import { fetchSetting } from '../../../redux/slice/setting'

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

//import SearchBar from './child/SearchBar';
//import Notifications from './child/Notifications';
//import Messages from './child/Messages';

import './Header.css';

let Header = (props) =>{

	const dispatch = useDispatch()
	const settingState = useSelector( (state)=> state.setting )  
    const setting      = (settingState.data) ? JSON.parse(settingState.data) : null;
	const [services,set_services] = useState([]) 

	useEffect(()=> {	
		dispatch(fetchSetting())				   
		fetchServices();				
	},[]) 	
	
	const fetchServices = async () => {		
		let q = query(collection(db, "services"));	        
        q = query(q, where("status", "==",  1 )); 
        q = query(q, orderBy('sort_order','asc'));	
		const querySnapshot = await getDocs(q);	
		let result = querySnapshot.docs.map((doc) => ({  
			...doc.data(), 
			id: doc.id,             
		})); 
		set_services(result)	
	}

	const hideNave = ()=>{
		let div = document.getElementById('navbar')
		if( div.classList.contains('navbar-mobile') ){ 			
			document.getElementById('mobile-nav-toggle').click()
		}
	}

	const downloadClick = () => {
        const pdfUrl = "/ajtechnoweb-brochure.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "ajtechnoweb-brochure.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

	let ccp   	        	= window.location.pathname;
	let ccp2     			= ccp.split('/')
	let current_path    	= (ccp2[1]) ? ccp2[1] : ''
	
	const home_menu 		= [''];  
	const about_menu       	= ['about-us']; 
	//const service_menu    = ['services','website-development','mobile-app-development','digital-marketing','graphic-design'];
	let service_menu    	= services.map( (item,i) => item.seo_url);
	service_menu.push('services')
	const ourworks_menu    	= ['our-works'];	
	const contact_menu    	= ['contact-us'];

	return (
		<>

		<HelmetProvider>
		<Helmet>	
		<script src="/assets/js/header-main.js" type="text/javascript" />
		</Helmet>				
		</HelmetProvider>

		<section id="topbar" className="d-flex align-items-center">
		{setting &&
			<div className="container d-flex justify-content-center justify-content-md-between">
			<div className="contact-info d-flex align-items-center">

			{ setting.contact_email ?
				<>
				<i className="bi bi-envelope d-flex align-items-center">
					<a href={`mailto:${setting.contact_email}`} style={{textDecoration:"none"}}>{setting.contact_email}</a>
				</i>
				</>
				: ''
			}

			{ setting.cantact_no ?
				<>
				<i className="bi bi-phone d-flex align-items-center ms-4">
					<a href={`tel:${setting.cantact_no}`} style={{textDecoration:"none"}}>{setting.cantact_no}</a>
				</i>
				</>
				: ''
			}	

			
            
			<i className="bi bi-filetype-pdf d-flex align-items-center corporate-brochure" onClick={downloadClick}>
				<span style={{cursor:"pointer"}} className='corporate-brochure-text'>Corporate Brochure</span>
			</i>

            {/* <i className="bi bi-filetype-pdf d-flex align-items-center corporate-brochure">
				<a href='/ajtechnoweb-brochure.pdf' style={{textDecoration:"none"}} download="myFile">Corporate Brochure</a>
			</i> */}
			
			
			</div>
			<div className="social-links d-none d-md-flex align-items-center">
			{ setting.twitter_url ? <a href={setting.twitter_url} target='_blank' className="twitter"><i className="bi bi-twitter"></i></a> : '' }	
			{ setting.facebook_url ? <a href={setting.facebook_url} target='_blank' className="facebook"><i className="bi bi-facebook"></i></a> : '' }
			{ setting.instagram_url ? <a href={setting.instagram_url} target='_blank' className="instagram"><i className="bi bi-instagram"></i></a> : '' }	
			{ setting.linkedin_url ? <a href={setting.linkedin_url} target='_blank' className="linkedin"><i className="bi bi-linkedin"></i></a> : '' }	
			{ setting.youtube_url ? <a href={setting.youtube_url} target='_blank' className="youtube"><i className="bi bi-youtube"></i></a> : '' }	
			{ setting.whatsapp_url ? <a href={setting.whatsapp_url} target='_blank' className="whatsapp"><i className="bi bi-whatsapp"></i></a> : '' }	
			</div>
			</div>
		}
		</section>

		<header id="header" className="d-flex align-items-center">
		<div className="container d-flex align-items-center justify-content-between">
		<Link to="/" className="logo"><img src="/logo.png" alt="" /></Link>
		<nav id="navbar" className="navbar">
		<ul>
			<li><Link className={`nav-link nav001 ${home_menu.includes(current_path) ? 'active' : ''}`} to="/" onClick={hideNave}>Home</Link></li> 
			<li><Link className={`nav-link nav001 ${about_menu.includes(current_path) ? 'active' : ''}`} to="/about-us" onClick={hideNave}>About Us</Link></li>
			<li className="dropdown">
			<Link className={`nav-link ${service_menu.includes(current_path) ? 'active' : ''}`} to="/services"><span>Services</span> 
			<i className="bi bi-chevron-down"></i></Link>
			<ul>
				{services.map( (item,i)=>{
					return(
						<li key={i}>
							<Link className={`nav002 ${ (current_path===item.seo_url) ? 'active' : ''}`} to={`/${item.seo_url}`} onClick={hideNave}>
							{item.title}
							</Link>
						</li>	
					)
				})}				
			</ul>
			</li>
			<li><Link className={`nav-link nav001 ${ourworks_menu.includes(current_path) ? 'active' : ''}`} to="/our-works" onClick={hideNave}>Our Works</Link></li>
			<li><Link className={`nav-link nav001 ${contact_menu.includes(current_path) ? 'active' : ''}`} to="/contact-us" onClick={hideNave}>Contact</Link></li>
		</ul>
		<i className="bi bi-list mobile-nav-toggle" id='mobile-nav-toggle'></i>
		</nav>
		</div>
		</header>
		</>     
    );
}
export default Header;