
import React, {useState,useEffect } from 'react';
import Parser, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'
import { fetchSetting } from '../../../redux/slice/setting'

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

const Footer = (props)=> {

	const dispatch = useDispatch()
	const settingState = useSelector( (state)=> state.setting )  
    const setting      = (settingState.data) ? JSON.parse(settingState.data) : null;
	const [services,set_services] = useState([]) 

	useEffect(()=> {	
		dispatch(fetchSetting())				   
		fetchServices();				
	},[]) 	
	
	const fetchServices = async () => {		
		let q = query(collection(db, "services"));	        
        q = query(q, where("status", "==",  1 )); 
        q = query(q, orderBy('sort_order','asc'));	
		const querySnapshot = await getDocs(q);	
		let result = querySnapshot.docs.map((doc) => ({ 
			...doc.data(), 
			id: doc.id,             
		})); 
		set_services(result)	
	}

	const parseWithLinks = (text)=>{
		const options = {     
		  replace: ({ name, attribs, children }) => {
			if (name === 'a' && attribs.href) {
			  return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
			}
		  }
		};        
		return Parser(text, options);
	  }


	let ccp   	        	= window.location.pathname;
	let ccp2     			= ccp.split('/')
	let current_path    	= (ccp2[1]) ? ccp2[1] : ''
	
	const home_menu 		= [''];  
	const about_menu       	= ['about-us']; 	
	let service_menu    	= services.map( (item,i) => item.seo_url);
	    service_menu.push('services')
	const ourworks_menu    	= ['our-works'];	
	const contact_menu    	= ['contact-us'];
	const terms_menu    	= ['terms-and-conditions'];	
	const privacy_menu    	= ['privacy-policy'];


	return (
		<>
		<footer id="footer">		
		<div className="footer-top">
			<div className="container">
			<div className="row">

				<div className="col-lg-3 col-md-6 footer-contact">				
				<Link to="/" className="logo"><img src="/logo.png" alt="" style={{width:"250px"}} /></Link>
				<p className="pt-3">
				{setting &&	setting.site_address &&
				    <>
					{Parser(setting.site_address)}
					<br /><br />					
					</>
				}
				{setting &&	setting.cantact_no &&
				    <>
					<strong>Phone: </strong> 
					<a href={`tel:${setting.cantact_no}`} style={{textDecoration:"none",color:"#333"}}>{setting.cantact_no}</a>
					<br />					
					</>
				}	
				{setting &&	setting.contact_email &&
				    <>
					<strong>Email: </strong> 
					<a href={`mailto:${setting.contact_email}`} style={{textDecoration:"none",color:"#333"}}>{setting.contact_email}</a>
					<br />					
					</>
				}							
				</p>
				</div>

				<div className="col-lg-3 col-md-6 footer-links">
				<h4>Useful Links</h4>
				<ul>
					<li><i className="bx bx-chevron-right"></i> <Link to="/" className={`${home_menu.includes(current_path) ? 'active' : ''}`}>Home</Link></li>
					<li><i className="bx bx-chevron-right"></i> <Link to="/about-us" className={`${about_menu.includes(current_path) ? 'active' : ''}`}>About Us</Link></li>
					<li><i className="bx bx-chevron-right"></i> <Link to="/our-works" className={`${ourworks_menu.includes(current_path) ? 'active' : ''}`}>Our Works</Link></li>
					<li><i className="bx bx-chevron-right"></i> <Link to="/contact-us" className={`${contact_menu.includes(current_path) ? 'active' : ''}`}>Contact</Link></li>
					<li><i className="bx bx-chevron-right"></i> <Link to="/terms-and-conditions" className={`${terms_menu.includes(current_path) ? 'active' : ''}`}>Terms and conditions</Link></li>
					<li><i className="bx bx-chevron-right"></i> <Link to="/privacy-policy" className={`${privacy_menu.includes(current_path) ? 'active' : ''}`}>Privacy policy</Link></li>
				</ul>
				</div>

				<div className="col-lg-3 col-md-6 footer-links">
				<h4>Our Services</h4>
				<ul>
					{services.map( (item,i)=>{
						return(						
							<li key={i}>
								<i className="bx bx-chevron-right"></i> 
								<Link to={`/${item.seo_url}`} className={`${ (current_path===item.seo_url) ? 'active' : ''}`}>
								{item.title}
								</Link>
							</li>	
						)
					})}				
				</ul>
				</div>

				<div className="col-lg-3 col-md-6 footer-links">				
				{setting &&	
				    <>
					<h4>Connect with Us on Social Media</h4>
					{Parser(setting.social_networks_footer_text)}
					<div className="social-links mt-3">
					{ setting.twitter_url ? <a href={setting.twitter_url} target='_blank' className="twitter"><i className="bx bxl-twitter"></i></a> : '' }	
					{ setting.facebook_url ? <a href={setting.facebook_url} target='_blank' className="facebook"><i className="bx bxl-facebook"></i></a> : '' }
					{ setting.instagram_url ? <a href={setting.instagram_url} target='_blank' className="instagram"><i className="bx bxl-instagram"></i></a> : '' }	
					{ setting.linkedin_url ? <a href={setting.linkedin_url} target='_blank' className="linkedin"><i className="bx bxl-linkedin"></i></a> : '' }	
					{ setting.youtube_url ? <a href={setting.youtube_url} target='_blank' className="youtube"><i className="bx bxl-youtube"></i></a> : '' }
					{ setting.whatsapp_url ? <a href={setting.whatsapp_url} target='_blank' className="whatsapp"><i className="bx bxl-whatsapp"></i></a> : '' }
					 
					</div>
					</>
				}				
				</div>

			</div>
			</div>
		</div>

		<div className="container py-4">
			<div className="copyright">
			{setting &&	
				parseWithLinks(setting.copyright_text)
            }
			</div>
			<div className="credits">
			{setting &&	
				parseWithLinks(setting.designed_by_text)
            }
			</div>
		</div>
		</footer>

		{/* <div id="preloader"></div> */}
		<a href="#" className="back-to-top d-flex align-items-center justify-content-center">
		<i className="bi bi-arrow-up-short"></i>
		</a>
		</>
	);
}
export default Footer;