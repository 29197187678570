import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { query, collection, getDocs, where } from "firebase/firestore";
import { auth, db, logout } from "../../config/firebase-config";

// action ( from api )
export const fetchSetting = createAsyncThunk("fetchSetting", async ()=>{     
    var objData = {};
    const promise = new Promise( async (resolve,reject)=>{
        const q = query( collection(db, "settings") );
        const querySnapshot = await getDocs(q);	
        querySnapshot.docs.map( (doc) => { 
            let item = doc.data() 
            objData[item.option] = item.value        
        }); 
        resolve(objData) 
    }).then( async(response)=>{
        objData = response;        
        return JSON.stringify(objData)         
    })  
    return promise  
})

// Slice
const slice = createSlice({
    name: 'setting',
    initialState: {
        isLoading:false,
        data: null,
        isError:false,        
    },
    extraReducers:(builder)=>{
        builder.addCase(fetchSetting.pending, (state,action)=>{
            state.isLoading = true
        })
        builder.addCase(fetchSetting.fulfilled, (state,action) =>{
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(fetchSetting.rejected, (state,action)=>{
            console.log('error',action.payload)
            state.isError = true
        })
    }
   
});
export default slice.reducer