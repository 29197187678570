
import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Link } from 'react-router-dom';
import Meta from '../../common/Meta';
import './Animation.css';

const Animation = (props)=>{ 

  const metaData = {
    meta_title			: 'Animation',
    meta_description	: '',
    meta_keywords		: '',
  } 

  return (  
    <>  
    <Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

    <main id="main">
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Animation</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li>Animation</li>            
          </ol>
        </div>
      </div>
    </section>
    <section className="inner-page">
      <div className="container">
      <div className="row">

            <div className="col-md-3">
            <div className="bgeffect-01">            
            <h3 className="title">Custom Web Development</h3>
            <p>We as a reliable <b>website development company</b> in Kolkata code your website based on
            the specific needs of your business. Our web developers perform high-quality coding work to customize
            your website to make it exclusively
            for your business. </p>
            </div>
            </div>


          <div className="col-md-3">
          <div className="bgeffect-02"> 
          <div className="inner-box">
              <div className="icon-box">
              <i className="bi bi-arrow-through-heart-fill"></i>
              </div>
              <h2>Mobile Compatibility</h2>
              <div className="text">We develop the code in a compatible way, where people can access the site through any kind of device. </div>
              <div className="overlay-box">
                  <div className="overlay-inner">
                      <div className="content">
                          <div className="icon-two">
                          <i className="bi bi-arrow-through-heart-fill"></i>
                          </div>
                          <h3><a href="#">Mobile Compatibility</a></h3>
                      </div>
                  </div>
              </div>
          </div>         
          </div>
          </div>


          <div className="col-md-3">
          <div className="bgeffect-03">          
          <div className="inner-box">
              <div className="inner-content">
                  <div className="icon-box">
                      <div className="icon">
                      <i className="bi bi-arrow-through-heart-fill"></i>
                      </div>
                  </div>
                  <h3>Creates Brand Awareness</h3>
                  <div className="text">The importance of branding to grow your business online and establishing your presence
                      cannot be overstated. By increasing your brand value with SEO, you can reach a more extensive clientele that
                      is fascinated by your business services. It has the potential to dramatically impact your company's most
                      critical goals, such as increasing sales and leads.
                  </div>        
              </div>
              <div className="overlay-box">
                  
              </div>
          </div>
          </div>
          </div>


          <div className="col-md-3">
          <div className="bgeffect-04">          
          <h3 className="title">Maintenance Support</h3>
          <p>At AJTECHNOWEB, we not only code your website but also assist you in maintaining it
          properly to help it perform flawlessly. Our representatives are always ready to assist you by resolving
          your queries to improve the performance
          of your websites </p>
          </div>
          </div>

      </div>
      </div>
    </section>
    </main>
    
    
    </>
  );
 
}
export default Animation;

