import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';
import Parser, { domToReact } from 'html-react-parser';
import { db } from "../../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

import Carousel from 'react-bootstrap/Carousel';
import './Banner.css';
const Banner = (props)=>{ 

  const filterData = {
    catid: "DmeOpVauI48p5hFG02RN",		
    status: 1,
  }

  const [data,set_data]  = useState([]) 
  
  useEffect( ()=>{	
    fetchData()
    //get_random_background_balls()
	},[])	

  const parseWithLinks = (text)=>{
    const options = {
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href) {
          return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
        }
      }
    };        
    return Parser(text, options);
  }

  const gueryFilter = ()=>{
		let q = query(collection(db, "banners"));		
		if(filterData.catid){
			q = query(q, where("catid", "==", filterData.catid));
		}
		if(filterData.status){
			q = query(q, where("status", "==", parseInt(filterData.status)));
		}	
		return q	
	}

  const fetchData = async ()=>{ 
		let q = gueryFilter();		
		    q = query(q, orderBy('sort_order','asc'));	
        const querySnapshot = await getDocs(q);	
        let result = querySnapshot.docs.map((doc) => ({ 
            ...doc.data(), 
            id: doc.id,             
        })); 
        set_data(result)
  }

  const get_random_background_balls = ()=>{
    // Some random colors
    const colors = ["#3CC157", "#2AA7FF", "#1B1B1B", "#FCBC0F", "#F85F36"];

    const numBalls = 10;
    const balls = [];

    for (let i = 0; i < numBalls; i++) {
      let ball = document.createElement("div");
      ball.classList.add("ball");
      ball.style.background = colors[Math.floor(Math.random() * colors.length)];
      ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
      ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
      ball.style.transform = `scale(${Math.random()})`;
      ball.style.width = `${Math.random()}em`;
      ball.style.height = ball.style.width;
      
      balls.push(ball);
      //document.body.append(ball)
      document.getElementById('hero').append(ball);
    }

    // Keyframes
    balls.forEach((el, i, ra) => {
      let to = {
        x: Math.random() * (i % 2 === 0 ? -11 : 11),
        y: Math.random() * 12
      };

      let anim = el.animate(
        [
          { transform: "translate(0, 0)" },
          { transform: `translate(${to.x}rem, ${to.y}rem)` }
        ],
        {
          duration: (Math.random() + 1) * 2000, // random duration
          direction: "alternate",
          fill: "both",
          iterations: Infinity,
          easing: "ease-in-out"
        }
      );
    });

  }

  return (  
    <>   
    <section id="hero" className="d-flex align-items-center section-bg" style={{minHeight:"350px"}}>
    <div className="container aos-init aos-animate" data-aos="zoom-out" data-aos-delay="100"> 

    {data.length > 0 &&        
        <Carousel 
        variant="dark" 
        interval={8000}
        controls={false}
        slide={true}
        >
          {data.map((item,i)=>{
            return (
              <Carousel.Item key={i}>
              <div className="row">
                <div className="col-md-12 col-lg-6"> 
                {parseWithLinks(item.banner_text)}
                </div>
                <div className="col-md-12 col-lg-6 servimg">
                <img src={item.banner_image} className="img-fluid" alt="" />
                </div>
              </div>
              </Carousel.Item>
            )
          })}  
        </Carousel>
    }  
    </div>
    </section>
    </>
  )
}
export default Banner