
import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Link } from 'react-router-dom';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";
import Parser, { domToReact } from 'html-react-parser';

import './Website_development.css';
import Meta from '../../common/Meta';

const Website_development = (props)=>{ 

    const [data,set_data]          = useState('') 
    const [metaData,set_metaData]  = useState({}) 

    useEffect( ()=>{	
      fetchData()   
    },[])	
  
    const fetchData = async ()=>{ 
        const docRef = doc(db, "services", "9iiaWMw8iQvXgJuUwgRE");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let docRow = { 
                ...docSnap.data(), 
                id: docSnap.id,             
            }
            set_data(docRow)
            set_metaData({
                meta_title		    : docRow.meta_title,
                meta_description	: docRow.meta_description,
                meta_keywords		: docRow.meta_keyword,
                seo_url		        : docRow.seo_url,
            })
        }
    }

    const parseWithLinks = (text)=>{
        const options = {     
          replace: ({ name, attribs, children }) => {
            if (name === 'a' && attribs.href) {
              return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
            }
          }
        };        
        return Parser(text, options);
    }

  return (  
    <>  
    <Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

    <main id="main">
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{ (data) ? data.title : '' }</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li>{ (data) ? data.title : '' }</li>            
          </ol>
        </div>
      </div>
    </section>    

    {/*
    <section className="webdevelopment">
        <div className="container">
            <div className="row">
                <div className="col-md-5">
                    <div className="text-uppercase sec-title">
                        <h2 className="title" style={{'color':'#000'}}>Ajtechnoweb </h2>
                        <h1 className="sec-head">Website Development Services</h1>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="inner-column1">
                        <p className="text-justify">A highly interactive and fully-functional website is needed for your
                            business to keep it on the right track. Be it helping sustain the fast-paced competition,
                            increasing brand name, highlighting products and services among customers,
                            or increasing sales and revenue, a website serves your business in multiple ways.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row mt-20">
                <div className="col-md-12">
                    <p className="text-justify">
                        We at AJTECHNOWEB, a renowned <b><a href="https://www.webomindapps.com/" style={{'color':'#333'}}>web
                                development company </a></b>have grabbed various opportunities to cater to businesses from
                        across the world with our <b>web development services</b>. We have served multiple industries with our expertise in website
                        development and possess years of expertise in this realm.<br /> We possess a good understanding of the
                        needs of businesses and develop
                        the most suitable website while ensuring it matches their core needs. Our professionals focus on the
                        appearance of your website while creating it. We also integrate the right features based on your
                        business needs and thus, help
                        you in achieving your business goal.<br /> As a web development company in Kolkata, we are a group
                        of creative web developers who are versatile to develop a variety of websites leveraging different
                        CMS platforms. We perform quality
                        coding work to make your website high-performing and business-friendly. We create a website that
                        promotes your products, increases sales, and ROI.
                    </p>
                </div>
            </div>
        </div>
    </section>

    <section className="featured-section-two">
    <div className="container">
        <div className="patern-layer" style={{'backgroundImage':'url(/assets/img/portfolio/website-development/pattern-7.png)'}}></div>
        <div className="patern-layer-two" style={{'backgroundImage':'url(/assets/img/portfolio/website-development/pattern-8.png)'}}>
        </div>
        <div className="container">
            <div className="row clearfix">

                <div className="blocks-column col-lg-6 col-md-12 col-sm-12">
                    <div className="row clearfix">

                        <div className="feature-block col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-box">
                                <div className="icon-box">
                                    <span className="icon">
                                    <i className="bi bi-phone-fill"></i>
                                    </span>
                                </div>
                                <h2>Mobile Compatibility</h2>
                                <div className="text">We develop the code in a compatible way, where people can access the site
                                    through any kind of device. </div>
                                <div className="overlay-box">
                                    <div className="overlay-inner">
                                        <div className="content">
                                            <div className="icon-two">
                                            <span className="icon">
                                            <i className="bi bi-phone-fill"></i> 
                                            </span>
                                            </div>
                                            <h3><a>Mobile
                                                    Compatibility</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="feature-block col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-box">
                                <div className="icon-box">
                                   <span className="icon">
                                   <i className="bi bi-arrow-clockwise"></i>    
                                    </span>
                                </div>
                                <h2>Fast load time</h2>
                                <div className="text">Making things load faster plays a key role in your business growth, we do
                                    it better with optimizing. </div>
                                <div className="overlay-box">
                                    <div className="overlay-inner">
                                        <div className="content">
                                            <div className="icon-two">
                                            <span className="icon">
                                            <i className="bi bi-arrow-clockwise"></i>
                                            </span>
                                            </div>
                                            <h3><a>Fast
                                                    load time</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="feature-block col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-box">
                                <div className="icon-box">
                                <span className="icon">
                                <i className="bi bi-browser-chrome"></i>    
                                </span>
                                </div>
                                <h2>Browser Consistency</h2> 
                                <div className="text">We penetrate through each and every browser, we make sure that it will be
                                    compatible with cross platforms </div>
                                <div className="overlay-box">
                                    <div className="overlay-inner">
                                        <div className="content">
                                            <div className="icon-two">
                                            <span className="icon">
                                            <i className="bi bi-browser-chrome"></i> 
                                            </span>
                                            </div>
                                            <h3><a>Browser Consistency</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="feature-block col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-box">
                                <div className="icon-box">
                                    <span className="icon">
                                    <i className="bi bi-code"></i>
                                    </span>
                                </div>
                                <h2>Usable forms</h2>
                                <div className="text">Efficient coding in usable forms makes the site to collect refined data
                                    from the perfect end users. </div>
                                <div className="overlay-box">
                                    <div className="overlay-inner">
                                        <div className="content">
                                            <div className="icon-two">
                                            <span className="icon">
                                            <i className="bi bi-code"></i>
                                            </span>
                                            </div>
                                            <h3><a>Usable
                                                    forms</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-column col-lg-6 col-md-12 col-sm-12">
                    <div className="inner-column">

                        <div className="sec-title">
                            <h2>What We Provide For your Dream Business</h2>
                            <p className="text-justify">We provide the static and dynamic web development service and keep your
                                website more engaging and easily visible to the customers through the internet. Get your
                                website developed as per your choice of design and requirements.
                                All you have to explain to us and we deliver the best results in an excellent way.</p>
                        </div>
                        <br />

                        <div className="blocks-outer mt-20">

                            <div className="feature-block-two">
                                <div className="inner-box">
                                    <div className="icon-box">
                                    <span className="icon">
                                    <i className="bi bi-check2-circle"></i>   
                                    </span>
                                    </div>
                                    <h3><a>High-Quality</a></h3>
                                    <p>we provide high-quality website development services in various platform</p>
                                </div>
                            </div>

                            <div className="feature-block-two">
                                <div className="inner-box">
                                    <div className="icon-box">
                                    <span className="icon">
                                    <i className="bi bi-check2-circle"></i>  
                                    </span>
                                    </div>
                                    <h3><a>Affordable prices</a></h3>
                                    <p>We develop Website with a mobile-friendly at affordable prices to our clients</p>
                                </div>
                            </div>
                            <div className="feature-block-two">
                                <div className="inner-box">
                                    <div className="icon-box">
                                    <span className="icon">
                                    <i className="bi bi-check2-circle"></i> 
                                    </span>
                                    </div>
                                    <h3><a>Highly specialized</a></h3>
                                    <p>Our highly specialized web developer understand your needs and requirements delivers
                                        the best results by using the latest trends and technologies. </p>
                                </div>
                            </div>

                            <Link  to="/contact-us" className="btn">
                                <span className="txt">Contact Us</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    
    <section className="service">
        <div className="container">
            <div className="row">
                <div className="col-md-5">
                    <div className="text-uppercase sec-title">                        
                        <h2 className="title" style={{'color':'#000'}}>Our Services </h2>
                        <h2 className="sec-head">Our Web Development Services Include</h2>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="inner-column1">
                        <p className="text-justify">As a website development company, we aim to serve maximum businesses from
                            different platforms with our world-className web development services. We at AJTECHNOWEB render a
                            wide range of website development services to
                            match the different needs of businesses. You can approach us with any of the services that you
                            need.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row mt-20">
                <div className="col-md-3">
                    <div className="features-item">
                    <span className="icon">
                    <i className="bi bi-pc-display"></i>   
                                    </span>       
                        <h3 className="feature-title">Custom Web Development</h3>
                        <p className="mrb-0">We as a reliable <b>website development company</b> in Kolkata code your website
                            based on the specific needs of your business. Our web developers perform high-quality coding
                            work to customize your website to make it exclusively
                            for your business. </p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="features-item">
                    <span className="icon">
                    <i className="bi bi-pc-display-horizontal"></i> 
                                    </span>       
                        <h3 className="feature-title">Web Portal Development</h3>
                        <p className="mrb-0">We are specialized in developing a wide range of web portals while ensuring it
                            matches your business requirements. Our professionals integrate the latest features,
                            functionalities, etc. into your website to make it fully functional
                            and high-performing. </p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="features-item">
                    <span className="icon">
                    <i className="bi bi-shop"></i>
                                    </span>       
                        <h3 className="feature-title">eCommerce Web Development</h3>
                        <p className="mrb-0">Be it integrating cutting-edge features, advanced security measures, or crafting
                            interactive user engagement, our professionals use every technique to make your eCommerce
                            website business-oriented and sales-driven. </p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="features-item">
                    <span className="icon">
                    <i className="bi bi-gear"></i> 
                                    </span>       
                        <h3 className="feature-title">Maintenance <br />&amp; Support</h3>
                        <p className="mrb-0">At AJTECHNOWEB, we not only code your website but also assist you in maintaining
                            it properly to help it perform flawlessly. Our representatives are always ready to assist you by
                            resolving your queries to improve the performance
                            of your websites </p>
                    </div>
                </div>
            </div>
        </div>
    </section>    

    <section className="tech" style={{'backgroundImage':'url(/assets/img/portfolio/website-development/bg1.jpg)'}}>
    <div className="background-overlay"></div>    
        <div className="container" style={{opacity:".98"}}>
            <div className="row">
                <div className="col-md-5">
                    <div className="text-uppercase sec-title">
                        <h2 className="title" style={{'color':'#ccc'}}>Technologies &amp; Frameworks </h2>
                        <h2 className="sec-head text-white">Technologies &amp; Frameworks of Web Development</h2>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="inner-column1">
                        <p className="text-justify text-white">We aim to provide best-in-className web development solutions by
                            leveraging the latest technologies and frameworks. We help you gain competitive advantages and
                            expand your business horizon successfully. Some of the technologies and
                            frameworks that we leverage most frequently for website development are;
                        </p>
                    </div>
                </div>
            </div>
            <br />
            <div className="row mt-20 justify-content-md-center">
                <div className="col-md-2">
                    <div className="ct-counter-holder swing">
                        <picture>
                        <img src="/assets/img/portfolio/website-development/php.webp" alt="php" className="img-responsive" width="100" height="77" />
                        </picture>
                    </div>
                    <h3 className="text-center text-white">PHP</h3>
                </div>
                <div className="col-md-2">
                    <div className="ct-counter-holder swing">
                        <picture>
                        <img src="/assets/img/portfolio/website-development/cake-php.webp" alt="cake php" className="img-responsive" width="100" height="77" />
                        </picture>
                    </div>
                    <h3 className="text-center text-white">Cake PHP</h3>
                </div>                
                <div className="col-md-2">
                    <div className="ct-counter-holder swing">
                        <picture>
                        <img src="/assets/img/portfolio/website-development/yii.webp" alt="yii" className="img-responsive" width="100" height="77" />
                        </picture>
                    </div>
                    <h3 className="text-center text-white">Yii</h3>
                </div>
                <div className="col-md-2">
                    <div className="ct-counter-holder swing">
                        <picture>
                        <img src="/assets/img/portfolio/website-development/react.webp" alt="React js" className="img-responsive" width="100" height="77" />
                        </picture>
                    </div>
                    <h3 className="text-center text-white">React</h3>
                </div>
                <div className="col-md-2">
                    <div className="ct-counter-holder swing">
                        <picture>
                        <img src="/assets/img/portfolio/website-development/phython.webp" alt="Python" className="img-responsive" width="100" height="77" />
                        </picture>
                    </div>
                    <h3 className="text-center text-white">Python</h3>
                </div>
                <div className="col-md-2">
                    <div className="ct-counter-holder swing">
                        <picture>
                        <img src="/assets/img/portfolio/website-development/dotnet.webp" alt="asp.net" className="img-responsive" width="100" height="77" />
                        </picture>
                    </div>
                    <h3 className="text-center text-white">ASP.NET</h3>
                </div>
            </div>
            <div className="row mt-20 justify-content-md-center">                
                <div className="col-md-2">
                    <div className="ct-counter-holder swing">
                        <picture>
                        <img src="/assets/img/portfolio/website-development/codignetor.webp" alt="CodeIgnitor" className="img-responsive" width="100" height="77" />
                        </picture>
                    </div>
                    <h3 className="text-center text-white">CodeIgnitor</h3>
                </div>
                <div className="col-md-2">
                    <div className="ct-counter-holder swing">
                        <picture>
                        <img src="/assets/img/portfolio/website-development/larvel.webp" alt="Laravel" className="img-responsive" width="100" height="77" />
                        </picture>
                    </div>
                    <h3 className="text-center text-white">Laravel</h3>
                </div>
                <div className="col-md-2">
                    <div className="ct-counter-holder swing">
                        <picture>
                        <img src="/assets/img/portfolio/website-development/symfony.webp" alt="Symfony" className="img-responsive" width="100" height="77" />
                        </picture>
                    </div>
                    <h3 className="text-center text-white">Symfony</h3>
                </div>
                <div className="col-md-2 col-md-offset-1">
                    <div className="ct-counter-holder swing">
                        <picture>
                        <img src="/assets/img/portfolio/website-development/java.webp" alt="Java" className="img-responsive" width="100" height="77" />
                        </picture>
                    </div>
                    <h3 className="text-center text-white">Java</h3>
                </div>
                <div className="col-md-2">
                    <div className="ct-counter-holder swing">
                        <picture>
                        <img src="/assets/img/portfolio/website-development/zend.webp" alt="Zend" className="img-responsive" width="100" height="77" />
                        </picture>
                    </div>
                    <h3 className="text-center text-white">Zend</h3>
                </div>
            </div>
        </div>        
    </section>   

    <section className="webdev05">
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-lg-6">
                    <picture>                        
                    <img src="/assets/img/portfolio/website-development/web-development-cycle.png" alt="Web Development Cycle" className="img-responsive top-bt"  />
                    </picture>
                </div>
                <div className="col-md-12 col-lg-6">
                <h3 className="process-head">Web Development Cycle</h3>
                        <hr />
                        <p className="text-justify">
                            We at AJTECHNOWEB work with a holistic approach to render top-notch web development
                            services. Our professionals follow agile methodology to create a website while
                            maintaining project consistency and also the specified time-frame. We follow the easy
                            web
                            development process with multiple steps that include;
                        </p>
                        <ul>
                        <li><i className="bi bi-1-circle"></i> <div className='text'>Gathering Relevant Information</div></li>
                        <li><i className="bi bi-2-circle"></i> <div className='text'>Planning - Sitemap and Wireframe</div></li>
                        <li><i className="bi bi-3-circle"></i> <div className='text'>Design & Layout</div></li>
                        <li><i className="bi bi-4-circle"></i> <div className='text'>Coding based on design</div></li>
                        <li><i className="bi bi-5-circle"></i> <div className='text'>Testing, Review</div></li>
                        <li><i className="bi bi-6-circle"></i> <div className='text'>Launch the website</div></li>
                        <li><i className="bi bi-7-circle"></i> <div className='text'>Maintenance and support</div></li>                        
                        </ul>
                        
                </div>
            </div>
        </div>
    </section>    

    <section className="future" style={{'backgroundImage':'url(/assets/img/portfolio/website-development/bg-section-01.png)'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="ct-heading h-align-  sub- ct-heading-left item-st-line-left1">
                        <h3 className="item--title st-line-left1">
                            <span>
                                <i></i>
                                Future Enhancement of Web Development </span>
                        </h3>
                    </div>
                </div>
                <div className="col-md-6">
                    <p className="text-justify">
                        At AJTECHNOWEB, we are not only specialized to develop future-proof websites that get vast user
                        engagement. We also assist you in improving the performance of your existing website by extending
                        its functionality and usability.
                    </p>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-12">
                    <p className="text-justify">We help you maintain the performance of your website by helping it get the vast
                        exposure from your target audience. We help your website get an edge over your competitors to
                        witness increased product sales and revenue generation.<br /> Our professionals chalk out the right
                        strategy to enhance your existing website by analyzing it in-depth. Once we review your website, we
                        approach you with a detailed strategy to keep your website up to date and make it future-ready.
                        Be it integrating new features, changing design, content update, migration from the existing
                        platform to others, we do everything to keep your website updated for your end-users.
                    </p>
                </div>
            </div>
        </div>
    </section>    

    <section className="why" style={{'backgroundImage':'url(/assets/img/portfolio/website-development/bg2.jpg)'}}>        
        <div className="container">
        <div className="my-overlay"></div>
            <div className="row">
                <div className="col-md-12">
                    <div className="ct-heading h-align-  sub- ct-heading-left item-st-line-left1">
                        <h3 className="item--title st-line-left1 text-white">
                            <span className="text-white">
                                <i></i>
                                Why Choose AJTECHNOWEB as a Website Development Company in Kolkata? </span>
                        </h3>
                    </div>
                    <p className="text-justify text-white" style={{opacity:'.9'}}>
                        We as a trusted web development agency are committed to cater to you with world-className web
                        development solutions. Our professionals stay in touch with you at every stage of web development to
                        get your feedback and implement it as per needed. We do everything
                        to create your website as per your needs and expectations to improve your business ROI. We are the
                        most preferred choice for web development globally for various reasons;
                    </p>
                </div>
            </div>
            <br />
            <div className="row mt-20">
                <div className="col-md-4">
                    <div className="grid-item-inner ">
                        <div className="grid-item-holder">
                            <div className="item--overlay"></div>
                            <div className="item--icon"><i className="bi bi-microsoft-teams"></i></div>
                            <h3 className="item--title">Talented Team</h3>
                            <div className="item--content">
                                We house a bunch of dexterous website developers who are highly efficient in coding work to
                                create a performance-driven website. Our professionals love to experiment with new tools and
                                technologies to create top-className websites.
                            </div>
                            <div className="item--icon-abs">
                                <i className="text-gradient flaticon-team"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="grid-item-inner ">
                        <div className="grid-item-holder">
                            <div className="item--overlay"></div>
                            <div className="item--icon"><i className="bi bi-speedometer"></i></div>
                            <h3 className="item--title">Scalability and Performance</h3>
                            <div className="item--content">
                                No matter what type of website you are looking to develop, we give you surety about its
                                performance and scalability. From planning to coding, we work on your project while keeping
                                scalability and performance in our minds.
                            </div>
                            <div className="item--icon-abs">
                                <i className="text-gradient flaticon-index"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="grid-item-inner ">
                        <div className="grid-item-holder">
                            <div className="item--overlay"></div>
                            <div className="item--icon"><i className="bi bi-browser-chrome"></i></div>
                            <h3 className="item--title">SEO-driven</h3>
                            <div className="item--content">
                                As a renowned <b>web development agency</b>, we create an SEO-friendly website for your
                                business while ensuring its improved online ranking. From designing to coding, we do
                                everything based on the latest SEO guidelines.
                            </div>
                            <div className="item--icon-abs">
                                <i className="text-gradient flaticon-analytics"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>    

    <section id="three-services">
    <div className="container">
        <div className="row">
            <div className="col-sm-4 inner-three-service">
                <div className="overlay over-bg-1"></div>
                <div className="z-index-99">
                <span className="icon">
                <i className="bi bi-pc-display"></i>
                                    </span>    
                    <h4 className="heading-service">CREATIVE WEBSITES</h4>
                    <p className="para-three-service">Best in className, Compatible with devices, We have set a standard in web
                        designing market </p>
                </div>
            </div>
            <div className="col-sm-4 inner-three-service">
                <div className="overlay over-bg-2"></div>
                <div className="z-index-99">
                <span className="icon">
                <i className="bi bi-code"></i> 
                                    </span>    
                    <h4 className="heading-service">CODING &amp; USABILITY</h4>
                    <p className="para-three-service">Expertized team in the latest technologies for implementing web
                        application development </p>
                </div>
            </div>
            <div className="col-sm-4 inner-three-service">
                <div className="overlay over-bg-3"></div>
                <div className="z-index-99">                
                                   <span className="icon">
                                   <i className="bi bi-google"></i>  
                                    </span>                               
                    <h4 className="heading-service">BE FIRST IN GOOGLE</h4>
                    <p className="para-three-service">Be first in the search engine to double your business by doubling your
                        conversion rate </p>
                </div>
            </div>
        </div>
        </div>
    </section>
     */}

    {data && data.section &&
        data.section.map( (item,i)=>{
            return(
                <div key={i}>
                {parseWithLinks(''+item.section+'')}
                </div>
            )
        })
    }

    </main>
    
    
    </>
  );
 
}
export default Website_development;

