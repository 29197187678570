
import React, {useState,useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';
import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';

import { db } from "../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore,startAt,endAt} from "firebase/firestore";

import ComingSoon from '../common/Coming_soon';

const Page_layout = (props)=>{  

  const [data,set_data] = useState({}) 
	
	useEffect(()=> {	
		fetch_Setting()	
	},[props]) 	

  const fetch_Setting = async ()=>{
    let q = query(collection(db, "settings"));	        
        q = query(q, where("option", "==",  "coming_soon" )); 
          const querySnapshot = await getDocs(q);	
          let result = querySnapshot.docs.map( (doc) => {				
              return { 
                  ...doc.data(), 
                  id: doc.id,             
              }
          })
    let docRow = result[0]
    set_data(docRow)    
  }
  return (  
    <>
    {data && parseInt(data.value) === 1 &&        
      <ComingSoon />  
    }
    {data && parseInt(data.value) === 0 && 
      <>
      <Header />    
      <props.component />
      <Footer />
      </>
    }    
    </>
  );
 
}
export default Page_layout;

