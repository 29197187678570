
import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Link } from 'react-router-dom';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";
import Parser, { domToReact } from 'html-react-parser';

import Meta from '../../common/Meta';
import './About.css';

const About = (props)=>{ 

  const [data,set_data]          = useState('') 
  const [metaData,set_metaData]  = useState('') 

  useEffect( ()=>{	
    fetchData()   
  },[])	

  const fetchData = async ()=>{ 
      let q = query(collection(db, "pages"));	        
          q = query(q, where("seo_url", "==",  "about-us" )); 
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map( (doc) => {				
				return { 
					...doc.data(), 
					id: doc.id,             
				}
			})
      let docRow = result[0]
      set_data(docRow)
      set_metaData({
        meta_title			  : docRow.meta_title,
        meta_description	: docRow.meta_description,
        meta_keywords		  : docRow.meta_keyword,
        seo_url		        : docRow.seo_url,
      })
  }

  const parseWithLinks = (text)=>{
    const options = {     
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href) {
          return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
        }
      }
    };        
    return Parser(text, options);
  }

  const bgImg = {
    background: 'url(assets/img/about/tab-bg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '550px 350px',
    backgroundPosition: '100% 115%'
  }  

  return (  
    <>  
    <Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>	
    </Helmet>				
    </HelmetProvider>

    <main id="main">
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>About Us</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li>About Us</li>
          </ol>
        </div>
      </div>
    </section>   
    
    { data ? parseWithLinks(""+data.content+"") :  <section className="inner-page"><div className="container"></div></section>  }  
    </main>
    
    </>
  );
 
}
export default About;

