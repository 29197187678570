import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';

import Parser, { domToReact } from 'html-react-parser';
import { db } from "../../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

import './Process.css';

const Process = (props)=>{   

  const [data,set_data]  = useState("")  

  useEffect( ()=>{	
    fetchData()   
  },[])	

  const parseWithLinks = (text)=>{
    const options = {     
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href) {
          return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
        }
      }
    };        
    return Parser(text, options);
  }

  const fetchData = async ()=>{ 
      let q = query(collection(db, "blocks"));	
          q = query(q, where("identity", "==", 'home_page_2' )); 
          q = query(q, where("status", "==",  1 )); 
      const querySnapshot = await getDocs(q);	
      let result = querySnapshot.docs.map((doc) => ({ 
          ...doc.data(), 
          id: doc.id,             
      })); 
      set_data(result[0])
  }

  return (     
    <>  
    { data ? parseWithLinks(""+data.content+"") :  ''  }    
    </>   
  )
}
export default Process