
import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Link } from 'react-router-dom';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";
import Parser, { domToReact } from 'html-react-parser';

import './Mobile_app_development.css';
import Meta from '../../common/Meta';

const Mobile_app_development = (props)=>{ 

    const [data,set_data]          = useState('') 
    const [metaData,set_metaData]  = useState({}) 

    useEffect( ()=>{	
      fetchData()   
    },[])	
  
    const fetchData = async ()=>{ 
        const docRef = doc(db, "services", "ZyKly9hGepyQeIginMKh");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let docRow = { 
                ...docSnap.data(), 
                id: docSnap.id,             
            }
            set_data(docRow)
            set_metaData({
                meta_title		    : docRow.meta_title,
                meta_description	: docRow.meta_description,
                meta_keywords		: docRow.meta_keyword,
                seo_url		        : docRow.seo_url,
            })
        }
    }

    const parseWithLinks = (text)=>{
        const options = {     
          replace: ({ name, attribs, children }) => {
            if (name === 'a' && attribs.href) {
              return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
            }
          }
        };        
        return Parser(text, options);
    }

  return (  
    <>  
    <Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

    <main id="main">
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{ (data) ? data.title : '' }</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li>{ (data) ? data.title : '' }</li>            
          </ol>
        </div>
      </div>
    </section>    
    
    {/*
    <section className="mob01">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="ct-heading h-align-  sub- ct-heading-left item-st-line-left1">
                        <h2 className="item--title st-line-left1">
                            <span> <i></i> Top-rated Mobile Application in Development Company in Kolkata</span>
                        </h2>
                    </div>
                </div>
                <div className="col-md-12">
                    <p className="text">
                        Ajtechnoweb is a cutting-edge mobile application development company in Kolkata that develops
                        ingenious mobile apps that make your business easily accessible on iPhones and Androids. For iOS and
                        Android platforms, our mobile app developers are proficient in creating applications using
                        Artificial Intelligence and Machine Learning technologies.

                    </p>
                    <p className="text">
                        The capability to meet your needs comes from our seasoned &amp; skilled app experts, who have
                        extensive experience in worldwide app solution services and product engineering industries. By
                        executing your ideas, our developers and designers devise proactive and consumer-pleasing mobile
                        applications. As a leading mobile app development company Kolkata, we provide innovative,
                        consumer-led, adaptable software to build solutions that offer your audience an impeccable,
                        user-friendly, and fascinating digital experience.</p>
                </div>
            </div>
            <hr />
            <div className="row text-center labl-serv">
                <div className="col-md-4">
                    <div className="card">  
                        <img src="/assets/img/services/mobile-app-development/android.png" alt="" className="img-responsive" />                        
                        <div className="card-body">
                            <h2 className="card-title">
                                Android
                            </h2>
                            <p className="card-text">You can do an incredible amount with a native Android app, it really opens
                                up a world of possibilities. As the best android app development company in Kolkata, our
                                Android developers have deep industry experience and use industry-leading tools like the
                                Android SDK Platform to develop successful Android apps.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">                        
                        <img src="/assets/img/services/mobile-app-development/apple.png" alt="" className="img-responsive" /> 
                        <div className="card-body">
                            <h2 className="card-title">
                                iOS
                            </h2>
                            <p className="card-text">Considering there are hundreds of millions of Apple iPhone and iPad users
                                across the world, iOS app development makes sense. Being an ios app development company in
                                Kolkata, we develop iOS apps for Apple hardware, including iPhone, iPad, and so on. Among
                                our specialties are trustworthy and well-built iOS applications. Ultimately, when it comes
                                to implementing ideas, we always push the limits.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">                        
                        <img src="/assets/img/services/mobile-app-development/react.png" alt="" className="img-responsive" /> 
                        <div className="card-body">
                            <h2 className="card-title">
                                Cross Platform
                            </h2>
                            <p className="card-text">Our apps are synergistic with all platforms and devices, making them
                                adaptable. With our expertise in creating cross-platform mobile app solutions, we can
                                facilitate you to develop mobile apps that work across Android and iOS devices. Being one of
                                the most credible mobile application development companies in Kolkata, you can count on us
                                to create a process that will give you the best chance of success.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>   

    <section className="mob02" style={{backgroundImage:'url(assets/img/services/mobile-app-development/web-banner.jpg)'}}>
          <div className='container col-12' style={{textAlign:"center"}}>
          <h2 >Application Development Process of <br />Mobile App Development @ Ajtechnoweb</h2>
          
          <div className="row justify-content-md-center" style={{textAlign:"left",paddingTop:"30px"}}>
              <div className="col-md-6 col-12">                
                    <div className="card">
                    <div className="row g-0">
                    <div className="col-md-3">
                    <div className="imageCenterAlign">
                    <img src="assets/img/services/mobile-app-development/systems-analysis.svg" className="img-fluid" alt="" />
                    </div>                      
                    </div>
                    <div className="col-md-9">
                    <div className="card-body">
                    <h5 className="card-title">01. Systems Analysis</h5>
                    <p className="card-text">As a first step, we take the time to understand the needs and objectives of our clients. We then embody their business philosophies, to ensure that our approach is as consistent as possible with their project requirements.</p>                    
                    </div>
                    </div>
                    </div>
                    </div>
              </div>
              <div className="col-md-6 col-12">
              <div className="card">
                    <div className="row g-0">
                    <div className="col-md-3">
                    <div className="imageCenterAlign">
                    <img src="assets/img/services/mobile-app-development/wireframing-designing.svg" className="img-fluid" alt="" />
                    </div>                      
                    </div>
                    <div className="col-md-9">
                    <div className="card-body">
                    <h5 className="card-title">02. Wireframing & Designing</h5>
                    <p className="card-text">In the next step, we create an app wireframe that outlines the platform details, feature blueprints, and practical alternatives. Afterward, our skilled team gives rise to mockups of distinct screens, so that our clients get a better idea and feel of the final app.</p>
                    </div>
                    </div>
                    </div>
                    </div>
              </div>
              <div className="col-md-6 col-12">
              <div className="card">
                    <div className="row g-0">
                    <div className="col-md-3">
                    <div className="imageCenterAlign">
                    <img src="assets/img/services/mobile-app-development/development.svg" className="img-fluid" alt="" />
                    </div>                      
                    </div>
                    <div className="col-md-9">
                    <div className="card-body">
                    <h5 className="card-title">03. Development</h5>
                    <p className="card-text">Providing seamless integration between designers, programmers, and developers. This team of experts crafts sturdy and dependable applications. To ensure a flawless product, we keep our clients updated along the way and attentively test the product at each stage. This will prevent any setbacks in the end.</p>
                    </div>
                    </div>
                    </div>
                    </div>
              </div>
              <div className="col-md-6 col-12">
              <div className="card">
                    <div className="row g-0">
                    <div className="col-md-3">
                    <div className="imageCenterAlign">
                    <img src="assets/img/services/mobile-app-development/testing-quality.svg" className="img-fluid" alt="" />
                    </div>                      
                    </div>
                    <div className="col-md-9">
                    <div className="card-body">
                    <h5 className="card-title">04. Testing & Quality Assurance</h5>
                    <p className="card-text">The sophisticated testing technology we employ covers the total quality check of the product. Once we’re done with testing the applications and it’s made certain that it is error-free, we get our client's approval to move to the next stage.</p>
                    </div>
                    </div>
                    </div>
                    </div>
              </div>
              <div className="col-md-6 col-12">
              <div className="card">
                    <div className="row g-0">
                    <div className="col-md-3">
                    <div className="imageCenterAlign">
                    <img src="assets/img/services/mobile-app-development/service-support.svg" className="img-fluid" alt="" />
                    </div>                      
                    </div>
                    <div className="col-md-9">
                    <div className="card-body">
                    <h5 className="card-title">05. Service & Support</h5>
                    <p className="card-text">Throughout our relationships with clients, we work in proximity to assist our clients with any support and maintenance service of apps despite the launch. Moreover, as the best app developers in Kolkata, we endeavor to expand and bring new features as your business builds.</p>
                    </div>
                    </div>
                    </div>
                    </div>
              </div>
          </div>    
      </div>
    </section>
    */}

    {data && data.section &&
        data.section.map( (item,i)=>{
            return(
                <div key={i}>
                {parseWithLinks(''+item.section+'')}
                </div>
            )
        })
    }

    </main>  
    </>
  );
 
}
export default Mobile_app_development;

