import Home from '../components/pages/home/Home';
import About from '../components/pages/about/About';
import Contact from '../components/pages/contact/Contact';
import Thank_you from '../components/pages/contact/Thank_you';
import Our_works from '../components/pages/our_works/Our_works';
import Work_details from '../components/pages/work_details/Work_details';

import Services from '../components/pages/services/Services';
import Website_development from '../components/pages/website_development/Website_development';
import Mobile_app_development from '../components/pages/mobile_app_development/Mobile_app_development';
import Digital_marketing from '../components/pages/digital_marketing/Digital_marketing';
import Graphic_design from '../components/pages/graphic_design/Graphic_design';

import Terms_and_conditions from '../components/pages/terms_and_conditions/Terms_and_conditions';
import Privacy_policy from '../components/pages/privacy_policy/Privacy_policy';
import Animation from '../components/pages/animation/Animation';


const routes = [  
  {path: '/', exact: true, component: Home }, 
  {path: '/about-us', exact: true, component: About },
  {path: '/contact-us', exact: true, component: Contact },  
  {path: '/services', exact: true, component: Services },

  {path: '/website-development', exact: true, component: Website_development },
  {path: '/mobile-app-development', exact: true, component: Mobile_app_development },
  {path: '/digital-marketing', exact: true, component: Digital_marketing },
  {path: '/graphic-design', exact: true, component: Graphic_design },

  {path: '/our-works', exact: true, component: Our_works}, 
  {path: '/work-details/:id', exact: true, component: Work_details}, 
  {path: '/terms-and-conditions', exact: true, component: Terms_and_conditions}, 
  {path: '/privacy-policy', exact: true, component: Privacy_policy}, 
  {path: '/animation', exact: true, component: Animation}, 
  {path: '/thank-you', exact: true, component: Thank_you},
];

export default routes;