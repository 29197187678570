
import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Link } from 'react-router-dom';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

import Meta from '../../common/Meta';

import Banner from './child/Banner';
import Intro from './child/Intro';
import Featured_services from './child/Featured_services';
import About from './child/About';
import Skills from './child/Skills';
import Counts from './child/Counts';
import Process from './child/Process';
import Clients from './child/Clients';
import Services from './child/Services';
import Testimonials from './child/Testimonials';
import Portfolio from './child/Portfolio';
import Team from './child/Team';
import Pricing from './child/Pricing';
import Faq from './child/Faq';
import Contact from './child/Contact';
import Newsletter from './child/Newsletter';

const Home = (props)=>{ 

  const [metaData,set_metaData]  = useState({}) 

  useEffect( ()=>{	
    fetchData()   
  },[])	

  const fetchData = async ()=>{ 
      let q = query(collection(db, "pages"));	        
          q = query(q, where("seo_url", "==",  "/" )); 
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map( (doc) => {				
				return { 
					...doc.data(), 
					id: doc.id,             
				}
			})
      let docRow = result[0]
      set_metaData({
        meta_title			  : docRow.meta_title,
        meta_description	: docRow.meta_description,
        meta_keywords		  : docRow.meta_keyword,
        seo_url		        : '',
      })
  }

  return (  
    <> 

    <Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>	
    </Helmet>				
    </HelmetProvider>

    <Banner />

    <main id="main">
    <Intro />
    <Featured_services />
    {/* <About /> */}
    {/* <Skills /> */}
    {/* <Counts /> */}
    <Process />
    {/* <Clients /> */}
    {/* <Services /> */}
    <Testimonials />
    <Portfolio />
    {/* <Team /> */}
    {/* <Pricing /> */}
    <Faq />
    {/* <Contact /> */}
    {/* <Newsletter />     */}
    </main>
    </>
  );
 
}
export default Home;

