import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';

import Parser, { domToReact } from 'html-react-parser';
import { db } from "../../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

import './Portfolio.css';
const Portfolio = (props)=>{ 
  
  const [data,set_data]  = useState([]) 

  useEffect( ()=>{	
    fetchData()   
  },[])	

  const parseWithLinks = (text)=>{
    const options = {     
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href) {
          return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
        }
      }
    };        
    return Parser(text, options);
  }

  const fetchData = async ()=>{ 
    var obj = []

    const promise_1 = new Promise( async (resolve,reject) =>{
			let q = query(collection(db, "portfolio"));	        
          q = query(q, where("status", "==",  1 )); 
          q = query(q, orderBy('create_date','desc'), limit(6) );		
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map( (doc) => {				
				return { 
					...doc.data(), 
					id: doc.id,             
				}
			})
			resolve(result)
		})

    const promise_2 = new Promise( async (resolve,reject) =>{
			let q = query(collection(db, "technologies"), orderBy('name','asc'));	
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map((doc) => ({ 
				...doc.data(), 
				id: doc.id,             
			})); 
			resolve(result)
		})

    Promise.all([promise_1, promise_2]).then( ([portfolio, technologies])=>{

			let objData = []

			portfolio.map( (item,i)=>{

        let technologiesStr   = item.technologies
        let technologiesArr   = technologiesStr.split(',')

        let technologies_data = technologiesArr.map( (tech,i)=>{
          return technologies.find( a => a.id === tech ).name
        })
				
				item['technologies_data'] = technologies_data
				objData.push(item)	

			})		
			set_data(objData)			
		})	    
   
  }

  return (  
    <>      

    <section id="portfolio" className="portfolio">
      <div className="container" data-aos="fade-up">

        <div className="section-title mb-5">          
          <h3>Explore Our <span>Latest Projects</span> and <span>Showcase</span> of
           <br />Our Recent Work</h3>
        </div>  

        <div className="row" data-aos="fade-up" data-aos-delay="200">  

        {data.map( (item,i)=>{

          return(
            <div className="col-lg-4 col-md-6" key={i}>          
            <div className="content">  
            <div className="content-overlay"></div>
            <img className="content-image" src={item.image} alt="" />
            <div className="content-details fadeIn-bottom">
            <h3 className="content-title">{item.name}</h3>
            <p className="content-text"><b>Technology :</b> {item.technologies_data.join(', ')}</p>
            <Link to={`/work-details/${item.id}`} className="btn m-1">Case Study</Link>    
            {item.project_url ? <a href={item.project_url} target='_blank' className="m-1"><i className="bi bi-arrow-up-right-circle-fill"></i></a> : ''}
            </div> 
            </div>          
            </div>
          )

        })}         

        </div>
      </div>
    </section>
    </>
  )
}
export default Portfolio