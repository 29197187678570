
import React, { Component } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Meta = (props)=>{ 

	let seo_url = (props.metaData.seo_url) ? props.metaData.seo_url : ''
 
    return (
		<>
		<HelmetProvider>
		<Helmet>		
		<title>{props.metaData.meta_title}</title>			
		<meta name="description" content={props.metaData.meta_description} /> 
		<link rel="canonical" href={process.env.REACT_APP_BASE_URL + '/' + seo_url } />

    	{/* Facebook Meta Tags */}
		<meta property="og:url" content="https://ajtechnoweb.com/" />
		<meta property="og:type" content="website" />
		<meta property="og:title" content={props.metaData.meta_title} />
		<meta property="og:description" content={props.metaData.meta_description} />
		<meta property="og:image" content="https://opengraph.b-cdn.net/production/images/c9151a70-eb73-46dc-af5f-2a1d8667a3e3.jpg?token=XACP6VDc_-4LRFf6hvx5Nb6o33oGLBTd9VL1pO9lghU&height=630&width=1200&expires=33261724896" />

		{/* Twitter Meta Tags */}
		<meta name="twitter:card" content="summary_large_image" />
		<meta property="twitter:domain" content="ajtechnoweb.com" />
		<meta property="twitter:url" content="https://ajtechnoweb.com/" />
		<meta name="twitter:title" content={props.metaData.meta_title} />
		<meta name="twitter:description" content={props.metaData.meta_description} />
		<meta name="twitter:image" content="https://opengraph.b-cdn.net/production/images/c9151a70-eb73-46dc-af5f-2a1d8667a3e3.jpg?token=XACP6VDc_-4LRFf6hvx5Nb6o33oGLBTd9VL1pO9lghU&height=630&width=1200&expires=33261724896" />
		
		</Helmet>				
		</HelmetProvider>	
		</>     
    );
  
}
export default Meta;