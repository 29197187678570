
import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Link } from 'react-router-dom';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";
import Parser, { domToReact } from 'html-react-parser';

import './Digital_marketing.css';
import Meta from '../../common/Meta';

const Digital_marketing = (props)=>{ 

    const [data,set_data]          = useState('') 
    const [metaData,set_metaData]  = useState({}) 

    useEffect( ()=>{	
      fetchData()   
    },[])	
  
    const fetchData = async ()=>{ 
        const docRef = doc(db, "services", "j0SidOjZBcyD4pDuJTww");
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()){
            let docRow = { 
                ...docSnap.data(), 
                id: docSnap.id,             
            }
            set_data(docRow)
            set_metaData({
                meta_title		    : docRow.meta_title,
                meta_description	: docRow.meta_description,
                meta_keywords		  : docRow.meta_keyword,
                seo_url		        : docRow.seo_url,
            })
        }
    }

    const parseWithLinks = (text)=>{
        const options = {     
          replace: ({ name, attribs, children }) => {
            if (name === 'a' && attribs.href) {
              return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
            }
          }
        };        
        return Parser(text, options);
    }

  return (  
    <>  
    <Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

    <main id="main">
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{ (data) ? data.title : '' }</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li>{ (data) ? data.title : '' }</li>            
          </ol> 
        </div>
      </div>
    </section>        
    
    {data && data.section &&
        data.section.map( (item,i)=>{
            return(
                <div key={i}>
                {parseWithLinks(''+item.section+'')}
                </div>
            )
        })
    }

    </main>
    </>
  );
 
}
export default Digital_marketing;

