
import React, {useState,useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { db } from "../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore,startAt,endAt} from "firebase/firestore";
import Parser, { domToReact } from 'html-react-parser';

import './Coming_soon.css';

const Coming_soon = (props)=>{  

	const [data,set_data] = useState({}) 
	
	useEffect(()=> {	
		fetch_Setting()	
	},[props]) 	

  const fetch_Setting = async ()=>{
    let q = query(collection(db, "settings"));	        
        q = query(q, where("option", "==",  "coming_soon_text" )); 
          const querySnapshot = await getDocs(q);	
          let result = querySnapshot.docs.map( (doc) => {				
              return { 
                  ...doc.data(), 
                  id: doc.id,             
              }
          })
    let docRow = result[0]
    set_data(docRow)    
  }

  const parseWithLinks = (text)=>{
    const options = {     
      replace: ({ name, attribs, children }) => {
        if (name === 'a' && attribs.href) {
          return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
        }
      }
    };        
    return Parser(text, options);
  }


  return (
		<>
		<section className="inner-page">
			<div className="container">
				<div className="row gx-5 gy-2">
				<div className="col-12 d-flex justify-content-center">
				{ data ? parseWithLinks(""+data.value+"") :  ''  }					
				</div>
				</div>
			</div>
		</section>
		</>     
    );
  
}
export default Coming_soon;