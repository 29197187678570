
import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Link } from 'react-router-dom';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

import Parser, { domToReact } from 'html-react-parser';

import { useSelector, useDispatch } from 'react-redux'
import { fetchSetting } from '../../../redux/slice/setting'

import Meta from '../../common/Meta';
import Contact_form from './Contact_form';

const Contact = (props)=>{ 

  const dispatch = useDispatch()
	const settingState = useSelector( (state)=> state.setting )  
  const setting      = (settingState.data) ? JSON.parse(settingState.data) : null;

  const [metaData,set_metaData]  = useState({}) 

  useEffect( ()=>{	
    fetchData()  
    dispatch(fetchSetting())	 
  },[])	

  const fetchData = async ()=>{ 
      let q = query(collection(db, "pages"));	        
          q = query(q, where("seo_url", "==",  "contact-us" )); 
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map( (doc) => {				
				return { 
					...doc.data(), 
					id: doc.id,             
				}
			})
      let docRow = result[0]
      set_metaData({
        meta_title			  : docRow.meta_title,
        meta_description	: docRow.meta_description,
        meta_keywords		  : docRow.meta_keyword,
        seo_url		        : docRow.seo_url,
      })
  }

  return (  
    <>  
    <Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

    <main id="main">
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Contact Us</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li>Contact Us</li>
          </ol>
        </div>
      </div>
    </section>
    <section id="contact" className="contact">
    <div className="container" data-aos="fade-up">       

      <div className="row" data-aos="fade-up" data-aos-delay="100">
        
      {setting &&	setting.google_map_iframe_url &&
        <div className="col-lg-6">
          <div className="row" data-aos="fade-up" data-aos-delay="100">
          
          {setting &&	setting.site_address &&
            <div className="col-lg-12">
            <div className="info-box mb-4">
            <i className="bx bx-map"></i>
            <h3>Our Address</h3><p style={{color:"#333"}}>{Parser(setting.site_address)}</p>
            </div>
            </div>
          } 
          
          {setting &&	setting.contact_email &&
            <div className="col-lg-12">
            <div className="info-box  mb-4">
            <i className="bx bx-envelope"></i>
            <h3>Email Us</h3> 
            <p><a href={`mailto:${setting.contact_email}`} style={{textDecoration:"none",color:"#333"}}>{setting.contact_email}</a></p>			
            </div>
            </div>
          }	
            
          {setting &&	setting.cantact_no &&
            <div className="col-lg-12">
            <div className="info-box  mb-4">
            <i className="bx bx-phone-call"></i>
            <h3>Call Us</h3>
            <p><a href={`tel:${setting.cantact_no}`} style={{textDecoration:"none",color:"#333"}}>{setting.cantact_no}</a></p>
            </div>
            </div>
          }

          </div>
        </div>
      }

      <div className="col-lg-6">
      <Contact_form />
      </div>

      </div>

      </div>
    </section>
    </main>
    </>
  );
 
}
export default Contact;

