import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation  } from 'react-router-dom';

import public_routes from './routes/public_routes';
import Page_layout from './components/layouts/Page_layout';

import './App.css';

function App(props) {
  return (
    <Router> 
        <Routes>
        
        {public_routes.map( (item,i) =>{           
            return <Route 
            key={i} 
            path={item.path} 
            element={              
              <Page_layout component={item.component} />              
              }
            />           
          })}  

        </Routes>
    </Router>
    
  );
}

export default App;
