
import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Link } from 'react-router-dom';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore,startAt,endAt} from "firebase/firestore";
import Parser, { domToReact } from 'html-react-parser';

import Meta from '../../common/Meta';
import './Our_works.css';

const Our_works = (props)=>{ 

    const queryParams   = new URLSearchParams(window.location.search) 
    const item_per_page = 6
		
	const __filterData = {
        industry: (queryParams.get('industry')) ? (queryParams.get('industry')) : '',
		service: (queryParams.get('service')) ? (queryParams.get('service')) : '',	
        technology: (queryParams.get('technology')) ? (queryParams.get('technology')) : '',		
	}

    const [pagedata,set_pagedata]                       = useState('') 
    const [metaData,set_metaData]                       = useState('')
    const [industries,set_industries]  					= useState([])
	const [services,set_services]  						= useState([])
	const [technologies,set_technologies]  		        = useState([])

    const [filterData,set_filterData] 		            = useState(__filterData) 	
	const [data,set_data]             		            = useState([]) 
	const [total,set_total] 		                    = useState(0)
	const [page, setPage]                               = useState(1);		
  
    useEffect( ()=>{	
      fetch_pagedata()  
      fetch_industries()
	  fetch_services()
	  fetch_technologies() 
    },[])

    useEffect( ()=>{
        fetch_data()
        setBrowserUrl()	      
    },[filterData])
    
    const parseWithLinks = (text)=>{
        const options = {     
          replace: ({ name, attribs, children }) => {
            if (name === 'a' && attribs.href) {
              return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
            }
          }
        };        
        return Parser(text, options);
    }

    const fetch_pagedata = async ()=>{ 
        let q = query(collection(db, "pages"));	        
            q = query(q, where("seo_url", "==",  "our-works" )); 
              const querySnapshot = await getDocs(q);	
              let result = querySnapshot.docs.map( (doc) => {				
                  return { 
                      ...doc.data(), 
                      id: doc.id,             
                  }
              })
        let docRow = result[0]
        set_pagedata(docRow)
        set_metaData({
          meta_title		: docRow.meta_title,
          meta_description	: docRow.meta_description,
          meta_keywords		: docRow.meta_keyword,
          seo_url		    : docRow.seo_url,
        })
    }

  
    const fetch_industries = async ()=>{ 
		let q = query(collection(db, "industries"), orderBy('name','asc'));	
        const querySnapshot = await getDocs(q);	
        let result = querySnapshot.docs.map((doc) => ({ 
            ...doc.data(), 
            id: doc.id,             
        })); 
        set_industries(result)	
        return result	
    }

	const fetch_services = async ()=>{ 
		let q = query(collection(db, "services"), orderBy('title','asc'));	
        const querySnapshot = await getDocs(q);	
        let result = querySnapshot.docs.map((doc) => ({ 
            ...doc.data(), 
            id: doc.id,             
        })); 		
        set_services(result)
        return result			
    }

	const fetch_technologies = async ()=>{ 
		let q = query(collection(db, "technologies"), orderBy('name','asc'));	
        const querySnapshot = await getDocs(q);	
        let result = querySnapshot.docs.map((doc) => ({ 
            ...doc.data(), 
            id: doc.id,             
        })); 		
        set_technologies(result)
        return result			
    }

    
    const gueryFilter = ()=>{ 		    
		let q = query(collection(db, "portfolio"));	
		    q = query(q, where("status", "==", 1));
		if(filterData.industry){
			q = query(q, where("industry_id", "==", filterData.industry));
		}
        if(filterData.service){
			q = query(q, where("service_id", "==", filterData.service));
		}   
        q = query(q, orderBy('create_date','desc'));    	
		return q	
	}

    const fetch_portfolio = async (item_per_page)=>{ 
        let objData = []       
		let q = gueryFilter();	
        if(item_per_page){
            q = query(q, limit(item_per_page)); 
        }                  	        	
        const querySnapshot = await getDocs(q);	
        let result = querySnapshot.docs.map( (doc) => {	
            let item = { 
                ...doc.data(), 
                id: doc.id,             
            } 
            let technologiesArr = item.technologies.split(',')
            if( filterData.technology ){                     
                if( technologiesArr.includes(filterData.technology) ){ 
                    objData.push(item)	
                }
            }	
            else{
                objData.push(item)	
            }          			
        })	
        return objData
    }

    const fetch_data = async ()=>{ 

		const promise_1 = new Promise( async (resolve,reject) =>{			
            let result = fetch_portfolio(item_per_page)
			resolve(result)
		})

		const promise_2 = new Promise( async (resolve,reject) =>{			
			let result = fetch_services()
			resolve(result)
		})

		const promise_3 = new Promise( async (resolve,reject) =>{			
			let result = fetch_industries()
			resolve(result)
		})

        const promise_4 = new Promise( async (resolve,reject) =>{			
			let result = fetch_technologies()
			resolve(result)
		})

		const promise_5 = new Promise( async (resolve,reject) =>{
            let result = fetch_portfolio('')
			resolve(result)
		})        

		Promise.all([promise_1, promise_2, promise_3, promise_4, promise_5]).then( ([portfolio, services, industries, technologies, total_result])=>{
            
			let objData = []
			portfolio.map( (item,i)=>{

				item['service_data'] = services.find( a => a.id === item.service_id )
				item['idustry_data'] = industries.find( a => a.id === item.industry_id )

                let technologiesStr   = item.technologies
                let technologiesArr   = technologiesStr.split(',')
                let technologies_data = technologiesArr.map( (tech,i)=>{
                return technologies.find( a => a.id === tech ).name
                })				
				item['technologies_data'] = technologies_data   

                objData.push(item)	
			})	
            
			set_data(objData)
			set_total(total_result.length)		
			setPage(1)
		})	
        
    }

    const showNext = async (item) => {		
        if(data.length === 0) {
        	alert("Thats all we have for now !")
        } 
		else{
            let objData = []
			let q = gueryFilter();		
		    q = query(q, limit(item_per_page), startAfter(item.create_date));	
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map((doc) => { 

                let item = {
                    ...doc.data(), 
                    id: doc.id
                }                  
                item['service_data'] = services.find( a => a.id === item.service_id )
				item['idustry_data'] = industries.find( a => a.id === item.industry_id )

                let technologiesStr   = item.technologies
                let technologiesArr   = technologiesStr.split(',')
                let technologies_data = technologiesArr.map( (tech,i)=>{
                    return technologies.find( a => a.id === tech ).name
                })				
				item['technologies_data'] = technologies_data   

                objData.push(item)	

			});  
			
            set_data(objData);
            setPage(page + 1)            
        }
    };

	const showPrevious = async (item) => {
        let objData = []
		let q = gueryFilter();		
		q = query(q, limitToLast(item_per_page), endBefore(item.create_date));	
		const querySnapshot = await getDocs(q);	
		let result = querySnapshot.docs.map((doc) => { 
			let item = {
                ...doc.data(), 
                id: doc.id
            }                  
            item['service_data'] = services.find( a => a.id === item.service_id )
            item['idustry_data'] = industries.find( a => a.id === item.industry_id )

            let technologiesStr   = item.technologies
            let technologiesArr   = technologiesStr.split(',')
            let technologies_data = technologiesArr.map( (tech,i)=>{
                return technologies.find( a => a.id === tech ).name
            })				
            item['technologies_data'] = technologies_data   

            objData.push(item)	         
		});   

		set_data(objData);
        setPage(page - 1)        
    };

    const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;
		set_filterData({...filterData, [field_name]: field_value})
	}	

    const setBrowserUrl = ()=>{	
		const location = new URL(window.location);
		location.search="";

		if(filterData.industry){
			location.searchParams.set('industry', filterData.industry);
		}
        if(filterData.service){
			location.searchParams.set('service', filterData.service);
		}
        if(filterData.technology){
			location.searchParams.set('technology', filterData.technology);
		}
		window.history.pushState({},'', location);
	}
  
    let total_page          = Math.ceil(total/item_per_page);  
	let start_text          = (total) ? ((page - 1) * item_per_page) + 1 : 0;
    let end_text            = (((page - 1) * item_per_page) > (total - item_per_page)) ? total : (((page - 1) * item_per_page) + item_per_page);
    let display_text        = `Showing ${start_text} to ${end_text}  of <strong>${total}</strong> results`;
	let sl_no               = (total) ? ((page - 1) * item_per_page) + 1 : 1;    
   

  return (  
    <>  
    <Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

    <main id="main">
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Our works</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li>Our works</li>
          </ol>
        </div>
      </div>
    </section>
    <section className="porject-section">        
        <div className="container">
            <div className="filter-block">
                <form method="POST" id="portfolio_filter_frm">
                    <div className="row d-flex justify-content-center">                    
                        <div className="col-lg-3 col-md-6 col-12 mb-4">
                            <select name="industry" id="industry" value={filterData.industry} onChange={handleChange} className="form-select custom-select">
                                <option value="">All Industries</option>
                                {industries &&
                                    industries.map( (item,i)=>{
                                        return(
                                            <option key={i} value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 mb-4">
                            <select name="service" id="service" value={filterData.service} onChange={handleChange} className="form-select custom-select">
                                <option value="">All Services</option>
                                {services &&
                                    services.map( (item,i)=>{
                                        return(
                                            <option key={i} value={item.id}>{item.title}</option>
                                        )
                                    })
                                }
                            </select> 
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 mb-4">
                            <select name="technology" id="technology" value={filterData.technology} onChange={handleChange} className="form-select custom-select">
                                <option value="">All Technologies</option>
                                {technologies &&
                                    technologies.map( (item,i)=>{
                                        return(
                                            <option key={i} value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </form>
            </div>
            <div className="clearfix"></div>
        </div>
    </section>


    <section className="porject-section-main">
    <div className='container'>

        {data &&
            data.map( (item,i)=>{
                sl_no++
                let reverse = ''
                if(sl_no % 2 != 0) {
                    reverse ='row-reverse'
                }
                return(
                    <div key={i} className={`row post-row ${reverse} m-1 mb-5`}>
                        <div className="col-lg-6 col-md-12 col-12 picture-boxes">                
                            <div className="thumb entry-thumbnail picture-boxes">
                            <img className="img-fluid" src={item.image} />                 
                            </div>                 
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">                
                            <div className="project-info">
                                <div className="overlay-innerd">
                                    <div className="cat-title-bs">
                                    <h3>{item.name}</h3>
                                    </div>
                                    <h5>{item.idustry_data.name} ({item.service_data.title})</h5>
                                    <p>{item.short_description}</p>
                                    <h5 className="pt-3">Technology Used</h5>
                                    <ul className="tech-list">
                                    {item.technologies_data &&
                                        item.technologies_data.map( (techdata,i)=>{
                                            return(
                                                <li key={i}>{techdata}</li>
                                            )
                                        })
                                    }
                                    </ul>
                                    <div className="link-block">
                                        <Link to={`/work-details/${item.id}`} className="r-button cast-sudy-btn">Case Study</Link>                                        
                                        {item.project_url ? <a href={item.project_url} target='_blank'><i className="bi bi-arrow-up-right-circle-fill"></i></a> : ''}
                                    </div>
                                </div>
                            </div>                
                        </div>            
                    </div>
                )
            })
           
        }

        {data && data.length===0 &&						
            <div className='text-center'>No record Found!</div>						
        } 	

        <div className="text-center load-more-block">
        {total_page>1 && 
            <>   
                <div className="float-right">
                <span className="p-3">{Parser(display_text)}</span>
                <div className="btn-group">
                
                {page === 1 ? 
                    <button type="button" className="btn btn-secondary" disabled>Previous</button>
                    : 
                    <button type="button" className="btn btn-primary" onClick={() => showPrevious(data[0]) } >Previous</button>
                }

                {page < total_page ? 
                    <button type="button" className="btn btn-primary" onClick={() => showNext(data[data.length - 1])}>Next</button>
                    : 
                    <button type="button" className="btn btn-secondary" disabled>Next</button>						
                }	

                </div>
                </div>               
                </>
            }				
        </div>
    </div>
    </section>

    </main>
    
    </>
  );
 
}
export default Our_works;

