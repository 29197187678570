import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

import { db } from "../../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

const Testimonials = (props)=>{  

  const [data,set_data]  = useState([]) 

  useEffect( ()=>{	
    fetchData()   
  },[])	

  const fetchData = async ()=>{ 
    let q = query(collection(db, "testimonial"));	        
        q = query(q, where("status", "==",  1 )); 
        q = query(q, orderBy('sort_order','asc'));	
    const querySnapshot = await getDocs(q);	
    let result = querySnapshot.docs.map((doc) => ({ 
        ...doc.data(), 
        id: doc.id,             
    })); 
    set_data(result)
  }

  if(!data){
    return '';
  }

  return (  
    <>  
    <section className="testimonials">      
      <div className="container" data-aos="zoom-in">
      <div className='row'>
        {/* <div className='col-lg-6 col-md-12 col-12 d-flex justify-content-center'><img src="assets/img/testimonials.png" className="img-fluid" alt="" /></div> */}
        <div className='col-lg-12 col-md-12 col-12' id="testimonials">
        <Carousel 
            variant="dark" 
            interval={8000}
            controls={true}
            slide={false}
        >
        {data.map( (item,i)=>{

          return(
            <Carousel.Item key={i}>
              <div className="swiper-slide">
                <div className="testimonial-item">
                  {item.profile_image ?
                    <img src={item.profile_image} className="testimonial-img" alt="" />
                    :
                    <img src="assets/img/profile.png" className="testimonial-img" alt="" />
                  }                  
                  <h3>{item.name}</h3>
                  <h4>{item.designation}</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    {item.description}
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </Carousel.Item> 
          )

        })}  
        </Carousel>
      </div>
      </div>
      </div>
    </section>
    </>
  )
}
export default Testimonials